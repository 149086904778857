// src/components/Footer.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linkedin, Facebook, MessageCircle } from 'lucide-react';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="py-8 bg-gray-900 text-white border-t border-gray-800">
            <div className="container mx-auto px-6">
                <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                    <div className="mb-6 md:mb-0">
                        <h3 className="text-xl font-bold mb-4">Dvakota</h3>
                    </div>

                    <div className="grid grid-cols-2 gap-8">
                        <div>
                            <h4 className="text-lg font-semibold mb-3">{t('footer.navigation')}</h4>
                            <ul className="space-y-2">
                                <li>
                                    <a href="/courses" className="text-gray-400 hover:text-orange-300 transition-colors">
                                        {t('header.courses')}
                                    </a>
                                </li>
                                <li>
                                    <a href="/about" className="text-gray-400 hover:text-orange-300 transition-colors">
                                        {t('header.about')}
                                    </a>
                                </li>
                                <li>
                                    <a href="/contact" className="text-gray-400 hover:text-orange-300 transition-colors">
                                        {t('header.contact_us')}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h4 className="text-lg font-semibold mb-3">{t('footer.legal')}</h4>
                            <ul className="space-y-2">
                                <li>
                                    <a href="/privacy-policy" className="text-gray-400 hover:text-orange-300 transition-colors">
                                        {t('footer.privacy_policy')}
                                    </a>
                                </li>
                                <li>
                                    <a href="/terms-of-service" className="text-gray-400 hover:text-orange-300 transition-colors">
                                        {t('footer.terms_of_service')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
                    <div className="mb-4 md:mb-0">
                        <p className="text-gray-400 text-sm">
                            {t('footer.copyright')}
                        </p>
                    </div>

                    <div className="flex space-x-4">
                        <a
                            href="https://linkedin.com/company/dvakota"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-orange-300 transition-colors"
                            aria-label="LinkedIn"
                        >
                            <Linkedin size={20} />
                        </a>
                        <a
                            href="https://www.facebook.com/people/Dvakotacom/61571825987914/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-orange-300 transition-colors"
                            aria-label="Facebook"
                        >
                            <Facebook size={20} />
                        </a>
                        <a
                            href="https://t.me/dvakotacom"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-400 hover:text-orange-300 transition-colors"
                            aria-label="Telegram"
                        >
                            <MessageCircle size={20} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;