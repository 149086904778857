import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../App';
import axios from 'axios';
import { Search, ChevronRight, Plus, Save, Trash2, Globe, Edit2, Check, X, Languages, Copy, AlertCircle, FileText, Download } from 'lucide-react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const Localization = () => {
    const { token } = useContext(AuthContext);
    const [translations, setTranslations] = useState({});
    const { i18n } = useTranslation();
    const languages = ['ru', 'en', 'sr', 'hr', 'es', 'pt', 'sl'];
    const [expandedPaths, setExpandedPaths] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPaths, setFilteredPaths] = useState([]);
    const [allPaths, setAllPaths] = useState([]);
    const [selectedPath, setSelectedPath] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedTranslations, setEditedTranslations] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [translateStatus, setTranslateStatus] = useState({ isLoading: false, error: null, success: false });
    const defaultLang = 'ru';
    const [newKeyDialog, setNewKeyDialog] = useState(false);
    const [newKeyPath, setNewKeyPath] = useState('');
    const [newKeyValue, setNewKeyValue] = useState('');
    const [changedKeys, setChangedKeys] = useState({});
    const fileInputRef = useRef(null);

    // Загружаем файлы локализации при старте
    useEffect(() => {
        loadTranslationsFromI18n();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Генерируем пути для навигации после загрузки переводов
    useEffect(() => {
        if (Object.keys(translations).length > 0) {
            generateAllPaths();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translations]);

    // Обрабатываем поисковый запрос
    useEffect(() => {
        if (searchQuery) {
            filterPaths();
        } else {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setFilteredPaths(allPaths);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, allPaths]);

    // Функция загрузки файлов локализации
    const loadTranslationsFromI18n = () => {
        setIsLoading(true);
        setError(null);

        try {
            const loadedTranslations = {};

            // Получаем все ресурсы из i18next
            for (const lang of languages) {
                // Получаем все переводы для текущего языка
                const resources = i18n.getResourceBundle(lang, 'translation');
                loadedTranslations[lang] = resources || {};
            }

            setTranslations(loadedTranslations);
        } catch (err) {
            console.error('Ошибка при загрузке переводов из i18n:', err);
            setError('Не удалось загрузить переводы');
        } finally {
            setIsLoading(false);
        }
    };

    // Функция, генерирующая все пути для дерева навигации
    const generateAllPaths = () => {
        const paths = [];
        const mainLang = defaultLang; // Используем язык по умолчанию как основу для навигации

        const addPathsRecursively = (obj, currentPath = '') => {
            Object.keys(obj).forEach(key => {
                const newPath = currentPath ? `${currentPath}.${key}` : key;

                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    paths.push({ path: newPath, isFolder: true });
                    addPathsRecursively(obj[key], newPath);
                } else {
                    paths.push({ path: newPath, isFolder: false });
                }
            });
        };

        if (translations[mainLang]) {
            addPathsRecursively(translations[mainLang]);
        }

        setAllPaths(paths);
        setFilteredPaths(paths);
    };

    // Фильтрация путей при поиске
    const filterPaths = () => {
        const filtered = allPaths.filter(item =>
            item.path.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredPaths(filtered);

        // Автоматически раскрываем папки, содержащие результаты поиска
        if (searchQuery) {
            const pathsToExpand = new Set();
            filtered.forEach(item => {
                let path = '';
                item.path.split('.').forEach(segment => {
                    if (path) path += '.';
                    path += segment;
                    pathsToExpand.add(path);
                });
            });
            setExpandedPaths(Array.from(pathsToExpand));
        }
    };

    // Получение значения по пути из объекта
    const getNestedValue = (obj, path) => {
        if (!obj || !path) return undefined;
        return path.split('.').reduce((prev, curr) => {
            return prev && prev[curr] !== undefined ? prev[curr] : undefined;
        }, obj);
    };

    // Установка значения по пути в объекте
    const setNestedValue = (obj, path, value) => {
        if (!obj || !path) return obj;

        const pathParts = path.split('.');
        let current = obj;

        for (let i = 0; i < pathParts.length - 1; i++) {
            if (!current[pathParts[i]]) {
                current[pathParts[i]] = {};
            }
            current = current[pathParts[i]];
        }

        current[pathParts[pathParts.length - 1]] = value;
        return obj;
    };

    // Управление раскрытием папок
    const toggleFolder = (path) => {
        setExpandedPaths(prev =>
            prev.includes(path)
                ? prev.filter(p => p !== path && !p.startsWith(`${path}.`))
                : [...prev, path]
        );
    };

    // Обработка выбора пути
    const handleSelect = (path, isFolder) => {
        if (isFolder) {
            toggleFolder(path);
        } else {
            setSelectedPath(path);
            const newEditedTranslations = {};

            languages.forEach(lang => {
                const value = getNestedValue(translations[lang], path);
                newEditedTranslations[lang] = value !== undefined ? value : '';
            });

            setEditedTranslations(newEditedTranslations);
            setEditMode(false);
        }
    };

    // Обработка изменения поискового запроса
    const handleSearchChange = debounce((e) => {
        setSearchQuery(e.target.value);
    }, 300);

    // Вход в режим редактирования
    const enterEditMode = () => {
        setEditMode(true);
    };

    // Отмена редактирования
    const cancelEdit = () => {
        setEditMode(false);
        // Сбрасываем изменения
        const newEditedTranslations = {};
        languages.forEach(lang => {
            const value = getNestedValue(translations[lang], selectedPath);
            newEditedTranslations[lang] = value !== undefined ? value : '';
        });
        setEditedTranslations(newEditedTranslations);
    };

    // Обработка изменения перевода
    const handleTranslationChange = (lang, value) => {
        setEditedTranslations(prev => ({
            ...prev,
            [lang]: value
        }));
    };

    // Сохранение изменений
    const saveTranslationsToI18n = () => {
        if (!selectedPath) return;

        setIsSaving(true);
        setError(null);

        try {
            // Обновляем локальное состояние
            const updatedTranslations = { ...translations };

            languages.forEach(lang => {
                // Обновляем значение в нужном месте вложенной структуры
                setNestedValue(updatedTranslations[lang], selectedPath, editedTranslations[lang]);

                // Сохраняем в i18n
                i18n.addResourceBundle(lang, 'translation', updatedTranslations[lang], true, true);
            });

            // Отмечаем ключ как измененный
            setChangedKeys(prev => ({
                ...prev,
                [selectedPath]: true
            }));

            setTranslations(updatedTranslations);
            setEditMode(false);
            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000);
        } catch (err) {
            console.error('Ошибка при сохранении переводов:', err);
            setError('Не удалось сохранить изменения');
        } finally {
            setIsSaving(false);
        }
    };

    // Автоматический перевод текста на все языки
    const translateToAllLanguages = async (sourceLang) => {
        if (!selectedPath || !editedTranslations[sourceLang]) return;

        setTranslateStatus({ isLoading: true, error: null, success: false });

        try {
            const sourceText = editedTranslations[sourceLang];
            const langsToTranslate = languages.filter(lang => lang !== sourceLang);
            const translatedTexts = {};

            // Отправляем запрос на перевод через AI
            const response = await axios.post('/api/openai/translate',
                {
                    source_text: sourceText,
                    source_lang: sourceLang,
                    target_langs: langsToTranslate
                },
                {
                    headers: { Authorization: `Token ${token}` }
                }
            );

            // Обрабатываем результат
            const translationResults = response.data.translations;
            for (const lang of langsToTranslate) {
                if (translationResults[lang]) {
                    translatedTexts[lang] = translationResults[lang];
                }
            }

            // Обновляем поля перевода
            setEditedTranslations(prev => ({
                ...prev,
                ...translatedTexts
            }));

            setTranslateStatus({ isLoading: false, error: null, success: true });
            setTimeout(() => setTranslateStatus(prev => ({ ...prev, success: false })), 3000);
        } catch (err) {
            console.error('Ошибка при переводе:', err);
            setTranslateStatus({
                isLoading: false,
                error: 'Не удалось выполнить перевод. Попробуйте позже.',
                success: false
            });
        }
    };

    // Отображение дерева ключей локализации
    const renderTree = (paths, basePath = '', level = 0) => {
        const folders = {};
        const files = [];

        // Группировка путей по папкам на текущем уровне
        paths.forEach(item => {
            if (basePath && !item.path.startsWith(basePath + '.')) return;

            const relativePath = basePath
                ? item.path.slice(basePath.length + 1)
                : item.path;

            if (!relativePath) return;

            const parts = relativePath.split('.');
            const currentPart = parts[0];

            if (parts.length === 1) {
                files.push(item);
            } else {
                if (!folders[currentPart]) {
                    folders[currentPart] = [];
                }

                folders[currentPart].push(item);
            }
        });

        return (
            <div className="pl-4" style={{ marginLeft: level * 8 + 'px' }}>
                {/* Отображаем папки */}
                {Object.entries(folders).map(([folder, items]) => {
                    const folderPath = basePath ? `${basePath}.${folder}` : folder;
                    const isExpanded = expandedPaths.includes(folderPath);

                    return (
                        <div key={folderPath}>
                            <div
                                className="flex items-center py-1 px-2 cursor-pointer hover:bg-gray-700 rounded text-sm text-gray-300 hover:text-white"
                                onClick={() => toggleFolder(folderPath)}
                            >
                                <ChevronRight
                                    size={16}
                                    className={`mr-1 transition-transform ${isExpanded ? 'rotate-90' : ''}`}
                                />
                                <span className="font-semibold">{folder}</span>
                            </div>

                            {isExpanded && renderTree(items, folderPath, level + 1)}
                        </div>
                    );
                })}

                {/* Отображаем файлы (ключи перевода) */}
                {files.map(item => (
                    <div
                        key={item.path}
                        className={`
                           ml-6 py-1 px-2 cursor-pointer hover:bg-gray-700 rounded text-sm 
                           ${selectedPath === item.path ? 'bg-gray-700 text-orange-400' : 'text-gray-300 hover:text-white'}
                           ${changedKeys[item.path] ? 'border-l-2 border-orange-500 pl-1' : ''}
                       `}
                        onClick={() => handleSelect(item.path, false)}
                    >
                        {item.path.split('.').pop()}
                    </div>
                ))}
            </div>
        );
    };

    // Открытие диалога создания нового ключа
    const openNewKeyDialog = () => {
        setNewKeyPath('');
        setNewKeyValue('');
        setNewKeyDialog(true);
    };

    // Обработка создания нового ключа
    const handleCreateNewKey = () => {
        if (!newKeyPath || !newKeyValue) return;

        setIsSaving(true);
        setError(null);

        try {
            // Обновляем локальное состояние
            const updatedTranslations = { ...translations };

            languages.forEach(lang => {
                setNestedValue(
                    updatedTranslations[lang],
                    newKeyPath,
                    lang === defaultLang ? newKeyValue : ''
                );

                i18n.addResourceBundle(lang, 'translation', updatedTranslations[lang], true, true);
            });

            // Сохраняем измененные ключи для последующего экспорта
            setChangedKeys(prev => ({
                ...prev,
                [newKeyPath]: true
            }));

            setTranslations(updatedTranslations);
            setNewKeyDialog(false);

            // Выбираем созданный ключ
            setSelectedPath(newKeyPath);
            const newEditedTranslations = {};

            languages.forEach(lang => {
                newEditedTranslations[lang] = lang === defaultLang ? newKeyValue : '';
            });

            setEditedTranslations(newEditedTranslations);
            setEditMode(true);

            // Обновляем пути
            generateAllPaths();

            // Раскрываем папки до нового ключа
            const newPathParts = newKeyPath.split('.');
            const pathsToExpand = [];
            let currentPath = '';

            for (let i = 0; i < newPathParts.length - 1; i++) {
                if (currentPath) currentPath += '.';
                currentPath += newPathParts[i];
                pathsToExpand.push(currentPath);
            }

            setExpandedPaths(prev => [...new Set([...prev, ...pathsToExpand])]);

        } catch (err) {
            console.error('Ошибка при создании нового ключа:', err);
            setError('Не удалось создать новый ключ');
        } finally {
            setIsSaving(false);
        }
    };

    // Копирование ключа в буфер обмена для использования в коде
    const handleCopyKeyPath = () => {
        if (!selectedPath) return;

        try {
            navigator.clipboard.writeText(`t('${selectedPath}')`);

            // Показываем уведомление об успешном копировании
            showNotification('Скопировано в буфер обмена', 'success');
        } catch (err) {
            console.error('Ошибка при копировании в буфер обмена:', err);
            showNotification('Не удалось скопировать', 'error');
        }
    };

    // Показ уведомления
    const showNotification = (message, type = 'info') => {
        const notification = document.createElement('div');
        notification.className = `fixed top-4 right-4 px-4 py-2 rounded shadow-lg z-50 ${
            type === 'success' ? 'bg-green-500 text-white' :
                type === 'error' ? 'bg-red-500 text-white' :
                    'bg-orange-500 text-white'
        }`;
        notification.textContent = message;
        document.body.appendChild(notification);

        setTimeout(() => {
            notification.remove();
        }, 3000);
    };

    // Обработка загрузки файла локализации
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const content = JSON.parse(e.target.result);

                // Определяем язык по имени файла
                const fileNameParts = file.name.split('.');
                const lang = fileNameParts[0]; // Предполагаем, что имя файла - это код языка

                if (languages.includes(lang)) {
                    // Обновляем переводы для этого языка
                    const updatedTranslations = { ...translations };
                    updatedTranslations[lang] = content;

                    // Обновляем в i18n
                    i18n.addResourceBundle(lang, 'translation', content, true, true);

                    setTranslations(updatedTranslations);

                    // Показываем уведомление об успешной загрузке
                    showNotification(`Файл ${file.name} успешно загружен`, 'success');

                    // Обновляем пути
                    if (lang === defaultLang) {
                        generateAllPaths();
                    }
                } else {
                    setError(`Неизвестный язык: ${lang}`);
                }
            } catch (err) {
                console.error('Ошибка при парсинге JSON файла:', err);
                setError('Ошибка при парсинге JSON файла');
            }
        };

        reader.readAsText(file);
    };

    // Обработка клика по кнопке загрузки файла
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    // Экспорт файла локализации
    const handleExportTranslations = (lang) => {
        if (!translations[lang]) return;

        // Получаем актуальные данные из i18n
        const resources = i18n.getResourceBundle(lang, 'translation');
        const jsonString = JSON.stringify(resources, null, 2);

        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${lang}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        showNotification(`Файл ${lang}.json успешно скачан`, 'success');
    };

    // Экспорт всех измененных файлов
    const handleExportAllChanges = () => {
        // Создаем ZIP архив со всеми файлами
        if (Object.keys(changedKeys).length === 0) {
            showNotification('Нет изменений для экспорта', 'info');
            return;
        }

        // В реальном приложении здесь мы бы создали ZIP-архив
        // Для упрощения просто экспортируем все файлы по очереди
        languages.forEach(lang => {
            handleExportTranslations(lang);
        });

        // Сбрасываем список измененных ключей
        setChangedKeys({});
    };

    // Удаление ключа
    const handleDeleteKey = () => {
        if (!selectedPath) return;

        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить ключ "${selectedPath}"?`);
        if (!confirmDelete) return;

        try {
            const updatedTranslations = { ...translations };

            languages.forEach(lang => {
                // Удаляем ключ для каждого языка
                const pathParts = selectedPath.split('.');
                const lastPart = pathParts.pop();
                let current = updatedTranslations[lang];

                // Находим родительский объект
                for (const part of pathParts) {
                    if (!current[part]) break;
                    current = current[part];
                }

                // Удаляем ключ, если он существует
                if (current && current[lastPart] !== undefined) {
                    delete current[lastPart];

                    // Сохраняем путь как измененный
                    setChangedKeys(prev => ({
                        ...prev,
                        [selectedPath]: true
                    }));
                }
            });

            setTranslations(updatedTranslations);
            setSelectedPath(null);

            // Обновляем пути
            generateAllPaths();

            showNotification(`Ключ "${selectedPath}" успешно удален`, 'success');
        } catch (err) {
            console.error('Ошибка при удалении ключа:', err);
            setError('Не удалось удалить ключ');
        }
    };

    // Отображение загрузки
    if (isLoading) {
        return (
            <div className="flex h-full items-center justify-center">
                <div className="text-white flex flex-col items-center">
                    <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin mb-4"></div>
                    <div>Загрузка локализаций...</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-full bg-gray-900 text-white">
            {/* Левая панель с деревом ключей */}
            <div className="w-1/3 border-r border-gray-700 flex flex-col">
                <div className="p-4 border-b border-gray-700">
                    <div className="relative mb-4">
                        <input
                            type="text"
                            placeholder="Поиск ключей локализации..."
                            className="w-full bg-gray-800 border border-gray-700 rounded py-2 px-3 pl-9 text-white text-sm"
                            onChange={(e) => handleSearchChange(e)}
                        />
                        <Search className="absolute left-3 top-2.5 text-gray-400" size={16} />
                    </div>

                    <div className="flex gap-2 mb-4">
                        <button
                            className="flex-1 bg-orange-500 hover:bg-orange-600 text-white rounded py-2 px-3 flex items-center justify-center text-sm"
                            onClick={openNewKeyDialog}
                        >
                            <Plus size={16} className="mr-1" />
                            Создать ключ
                        </button>

                        <button
                            className="flex-1 bg-gray-700 hover:bg-gray-600 text-white rounded py-2 px-3 flex items-center justify-center text-sm"
                            onClick={handleUploadClick}
                        >
                            <FileText size={16} className="mr-1" />
                            Загрузить файл
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            accept=".json"
                            className="hidden"
                        />
                    </div>

                    {Object.keys(changedKeys).length > 0 && (
                        <button
                            className="w-full bg-green-500 hover:bg-green-600 text-white rounded py-2 px-3 flex items-center justify-center text-sm mb-4"
                            onClick={handleExportAllChanges}
                        >
                            <Download size={16} className="mr-1" />
                            Экспортировать изменения ({Object.keys(changedKeys).length})
                        </button>
                    )}

                    <div className="text-xs text-gray-400 mb-2">
                        <div className="flex items-center mb-1">
                            <div className="w-2 h-2 bg-orange-500 rounded-full mr-2"></div>
                            <span>Измененные ключи отмечены оранжевым.</span>
                        </div>
                        <div>Выберите язык и экспортируйте файл после завершения работы.</div>
                    </div>
                </div>
                <div className="flex-1 overflow-y-auto p-2">
                    {renderTree(filteredPaths)}
                </div>
            </div>

            {/* Правая панель с деталями перевода */}
            <div className="flex-1 flex flex-col overflow-hidden">
                {selectedPath ? (
                    <>
                        <div className="p-4 border-b border-gray-700 flex justify-between items-center">
                            <div className="flex-1 truncate">
                                <h3 className="text-lg font-bold text-white">{selectedPath}</h3>
                                <div className="text-gray-400 text-sm">{`Ключ локализации: ${selectedPath}`}</div>
                            </div>
                            <div className="flex gap-2">
                                <button
                                    className="p-2 text-white hover:bg-gray-700 rounded-full"
                                    onClick={handleCopyKeyPath}
                                    title="Копировать путь для использования"
                                >
                                    <Copy size={18} />
                                </button>
                                {!editMode ? (
                                    <>
                                        <button
                                            className="p-2 text-white hover:bg-gray-700 rounded-full"
                                            onClick={enterEditMode}
                                            title="Редактировать"
                                        >
                                            <Edit2 size={18} />
                                        </button>
                                        <button
                                            className="p-2 text-red-500 hover:bg-gray-700 rounded-full"
                                            onClick={handleDeleteKey}
                                            title="Удалить ключ"
                                        >
                                            <Trash2 size={18} />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="p-2 text-green-500 hover:bg-gray-700 rounded-full"
                                            onClick={saveTranslationsToI18n}
                                            disabled={isSaving}
                                            title="Сохранить"
                                        >
                                            {isSaving ? (
                                                <div className="w-4.5 h-4.5 border-2 border-green-500 border-t-transparent rounded-full animate-spin" />
                                            ) : (
                                                <Check size={18} />
                                            )}
                                        </button>
                                        <button
                                            className="p-2 text-red-500 hover:bg-gray-700 rounded-full"
                                            onClick={cancelEdit}
                                            title="Отменить"
                                        >
                                            <X size={18} />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="flex-1 overflow-y-auto p-4">
                            {error && (
                                <div className="mb-4 p-3 bg-red-500/20 text-red-500 rounded flex items-start">
                                    <AlertCircle size={18} className="mr-2 mt-0.5 flex-shrink-0" />
                                    <div>{error}</div>
                                </div>
                            )}

                            {saveSuccess && (
                                <div className="mb-4 p-3 bg-green-500/20 text-green-500 rounded flex items-start">
                                    <Check size={18} className="mr-2 mt-0.5 flex-shrink-0" />
                                    <div>Изменения успешно сохранены. Не забудьте экспортировать файлы для обновления локализации.</div>
                                </div>
                            )}

                            {translateStatus.success && (
                                <div className="mb-4 p-3 bg-blue-500/20 text-blue-500 rounded flex items-start">
                                    <Check size={18} className="mr-2 mt-0.5 flex-shrink-0" />
                                    <div>Перевод успешно выполнен для всех языков.</div>
                                </div>
                            )}

                            {translateStatus.error && (
                                <div className="mb-4 p-3 bg-red-500/20 text-red-500 rounded flex items-start">
                                    <AlertCircle size={18} className="mr-2 mt-0.5 flex-shrink-0" />
                                    <div>{translateStatus.error}</div>
                                </div>
                            )}

                            <div className="space-y-6">
                                {languages.map(lang => (
                                    <div key={lang} className="bg-gray-800 rounded-lg p-4 border border-gray-700">
                                        <div className="flex justify-between items-center mb-2">
                                            <div className="flex items-center">
                                                <div className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center text-sm font-bold uppercase">
                                                    {lang}
                                                </div>
                                                <h4 className="ml-2 font-semibold">
                                                    {lang === 'ru' ? 'Русский' :
                                                        lang === 'en' ? 'English' :
                                                            lang === 'sr' ? 'Српски' :
                                                                lang === 'hr' ? 'Hrvatski' :
                                                                    lang === 'es' ? 'Español' :
                                                                        lang === 'pt' ? 'Português' :
                                                                            lang === 'sl' ? 'Slovenščina' : lang}
                                                </h4>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                {editMode && lang !== defaultLang && (
                                                    <button
                                                        className="text-xs bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded flex items-center"
                                                        onClick={() => translateToAllLanguages(defaultLang)}
                                                        disabled={translateStatus.isLoading}
                                                        title={`Перевести с ${defaultLang} на ${lang}`}
                                                    >
                                                        {translateStatus.isLoading ? (
                                                            <>
                                                                <div className="w-3 h-3 border-2 border-white border-t-transparent rounded-full animate-spin mr-1"></div>
                                                                Перевод...
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Globe size={12} className="mr-1" />
                                                                Перевести
                                                            </>
                                                        )}
                                                    </button>
                                                )}
                                                <button
                                                    className="text-xs bg-gray-700 hover:bg-gray-600 text-white px-2 py-1 rounded flex items-center"
                                                    onClick={() => handleExportTranslations(lang)}
                                                    title={`Экспортировать ${lang}.json`}
                                                >
                                                    <Download size={12} className="mr-1" />
                                                    Экспорт
                                                </button>
                                            </div>
                                        </div>
                                        {editMode ? (
                                            <textarea
                                                value={editedTranslations[lang] || ''}
                                                onChange={(e) => handleTranslationChange(lang, e.target.value)}
                                                className="w-full h-32 bg-gray-900 border border-gray-700 rounded p-3 text-white"
                                                placeholder={`Введите перевод для ${lang}...`}
                                            />
                                        ) : (
                                            <div className="bg-gray-900 rounded p-3 min-h-[32px] whitespace-pre-wrap">
                                                {editedTranslations[lang] || <span className="text-gray-500 italic">Перевод отсутствует</span>}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {editMode && (
                            <div className="p-4 border-t border-gray-700 flex justify-end gap-2">
                                <button
                                    className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded"
                                    onClick={cancelEdit}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded flex items-center"
                                    onClick={saveTranslationsToI18n}
                                    disabled={isSaving}
                                >
                                    {isSaving ? (
                                        <>
                                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                                            Сохранение...
                                        </>
                                    ) : (
                                        <>
                                            <Save size={18} className="mr-2" />
                                            Сохранить
                                        </>
                                    )}
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full p-4">
                        <Languages size={48} className="text-gray-500 mb-4" />
                        <h3 className="text-xl font-bold text-white mb-2">Управление локализацией</h3>
                        <p className="text-gray-400 text-center max-w-md mb-6">
                            Выберите ключ локализации из дерева слева для просмотра и редактирования переводов для всех языков.
                        </p>
                        <div className="flex gap-3">
                            <button
                                className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded flex items-center"
                                onClick={openNewKeyDialog}
                            >
                                <Plus size={18} className="mr-2" />
                                Создать новый ключ
                            </button>
                            <button
                                className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded flex items-center"
                                onClick={handleUploadClick}
                            >
                                <FileText size={18} className="mr-2" />
                                Загрузить файл
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Модальное окно создания нового ключа */}
            {newKeyDialog && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-bold text-white mb-4">Создание нового ключа локализации</h3>

                        <div className="mb-4">
                            <label className="block text-gray-300 text-sm font-bold mb-2">
                                Путь к ключу
                            </label>
                            <input
                                type="text"
                                value={newKeyPath}
                                onChange={(e) => setNewKeyPath(e.target.value)}
                                placeholder="Например: header.title или footer.copyright"
                                className="w-full bg-gray-900 border border-gray-700 rounded py-2 px-3 text-white"
                            />
                            <p className="mt-1 text-xs text-gray-400">
                                Используйте точки для создания вложенной структуры
                            </p>
                        </div>

                        <div className="mb-6">
                            <label className="block text-gray-300 text-sm font-bold mb-2">
                                Значение по умолчанию ({defaultLang})
                            </label>
                            <textarea
                                value={newKeyValue}
                                onChange={(e) => setNewKeyValue(e.target.value)}
                                placeholder={`Введите текст на ${defaultLang}...`}
                                className="w-full h-24 bg-gray-900 border border-gray-700 rounded py-2 px-3 text-white"
                            />
                            <p className="mt-1 text-xs text-gray-400">
                                Это значение будет установлено для основного языка ({defaultLang})
                            </p>
                        </div>

                        <div className="flex justify-end gap-2">
                            <button
                                className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded"
                                onClick={() => setNewKeyDialog(false)}
                            >
                                Отмена
                            </button>
                            <button
                                className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded flex items-center"
                                onClick={handleCreateNewKey}
                                disabled={!newKeyPath || !newKeyValue || isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                                        Создание...
                                    </>
                                ) : (
                                    <>
                                        <Plus size={18} className="mr-2" />
                                        Создать
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Localization;