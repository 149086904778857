import React, { useState, useEffect, createContext } from 'react';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingHeader from './components/LandingHeader';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { getUserInfo } from './services/apiService';
import './i18n';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import AnalyticsTracker from './components/AnalyticsTracker';
import AppRoutes from './AppRoutes';

export const AuthContext = createContext(null);

const AppContent = ({ children, isAuthenticated }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/' && !isAuthenticated;

  return (
      <div className={`flex flex-col min-h-screen w-screen overflow-x-hidden ${isLandingPage ? 'bg-black' : 'bg-white'}`}>
        {children}
      </div>
  );
};

function App() {
  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || Cookies.get('token') || null;
  });
  const [userInfo, setUserInfo] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const updateToken = (newToken) => {
    setToken(newToken);
    setIsAuthenticated(true);
  };
  const { i18n } = useTranslation();

  // При загрузке компонента проверяем сохранённый язык в cookies
  useEffect(() => {
    const savedLanguage = Cookies.get('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Восстанавливаем язык из cookies
    }
  }, [i18n]);

  // Сохраняем язык в cookies при его изменении
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      Cookies.set('language', lng, { expires: 7 });
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    if (token) {
      const fetchUserInfo = async () => {
        const userData = await getUserInfo(token);
        setUserInfo(userData);
      };
      fetchUserInfo();
    }
  }, [token]);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      if (token) {
        try {
          const userData = await getUserInfo(token);
          setUserInfo(userData);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Failed to get user info:', error);
          handleLogout();
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkAuth();
  }, [token]);

  const handleLoginSuccess = async (userToken) => {
    setToken(userToken);
    localStorage.setItem('token', userToken);
    Cookies.set('token', userToken, { expires: 7 });
    try {
      const userData = await getUserInfo(userToken);
      setUserInfo(userData);
      setIsAuthenticated(true);
      setLoginOpen(false);
    } catch (error) {
      console.error('Failed to get user info after login:', error);
      handleLogout();
    }
  };

  const handleLogout = () => {
    setToken(null);
    setUserInfo(null);
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    Cookies.remove('token');
  };

  const handleOpenLogin = () => setLoginOpen(true);
  const handleCloseLogin = () => setLoginOpen(false);
  const handleOpenRegister = () => setRegisterOpen(true);
  const handleCloseRegister = () => setRegisterOpen(false);
  const handleRegisterSuccess = () => {
    setRegisterOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, userInfo, token, isLoading, updateToken }}>
          <Router>
            <AnalyticsTracker />
            <AppContent isAuthenticated={isAuthenticated}>
              {isAuthenticated ? (
                  <Header
                      userInfo={userInfo}
                      onLogout={handleLogout}
                      onLoginClick={handleOpenLogin}
                      onRegisterClick={handleOpenRegister}
                  />
              ) : (
                  <LandingHeader
                      onLoginClick={handleOpenLogin}
                      onRegisterClick={handleOpenRegister}
                      onLoginSuccess={handleLoginSuccess}
                  />
              )}
              <div className="flex-grow">
                <AppRoutes
                    handleOpenLogin={handleOpenLogin}
                    handleLoginSuccess={handleLoginSuccess}
                    handleLogout={handleLogout}
                    loginOpen={loginOpen}
                    handleCloseLogin={handleCloseLogin}
                    registerOpen={registerOpen}
                    handleCloseRegister={handleCloseRegister}
                    handleRegisterSuccess={handleRegisterSuccess}
                />
              </div>
              <Footer />
            </AppContent>
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
  );
}

export default App;