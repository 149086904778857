import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthDialog from './AuthDialog';
import { Menu, X, ChevronDown, Globe } from 'lucide-react';

const LandingHeader = ({ onLoginSuccess }) => {
    const { t, i18n } = useTranslation();
    const [scrolled, setScrolled] = useState(false);
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [initialAuthMode, setInitialAuthMode] = useState('choice');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

    // Мемоизация объекта языков
    const supportedLanguages = useMemo(() => ({
        en: 'English',
        ru: 'Русский',
        sr: 'Српски',
        hr: 'Hrvatski',
        es: 'Español',
        pt: 'Português',
        sl: 'Slovenščina'
    }), []);

    // Текущий язык
    const currentLanguage = supportedLanguages[i18n.language] || supportedLanguages.en;

    // Обработчик скролла
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Мемоизация обработчиков событий
    const handleAuthClick = useCallback((mode) => {
        setInitialAuthMode(mode);
        setShowAuthDialog(true);
    }, []);

    const changeLanguage = useCallback((lng) => {
        i18n.changeLanguage(lng);
        setIsLanguageMenuOpen(false);
    }, [i18n]);

    const toggleMenu = useCallback(() => {
        setIsMenuOpen(prevState => !prevState);
    }, []);

    const toggleLanguageMenu = useCallback(() => {
        setIsLanguageMenuOpen(prevState => !prevState);
    }, []);

    return (
        <>
            <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
                scrolled ? 'bg-gray-900/90 backdrop-blur-lg' : 'bg-gray-900'
            } py-4 border-b border-gray-800`}
                    role="banner"
            >
                <div className="container mx-auto px-6 flex justify-between items-center">
                    <Link to="/" className="text-2xl font-bold text-white">
                        Dvakota
                    </Link>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center gap-6">
                        <Link to="/courses" className="text-white hover:text-orange-300 transition-colors">
                            {t('header.courses')}
                        </Link>
                        <Link to="/about" className="text-white hover:text-orange-300 transition-colors">
                            {t('header.about')}
                        </Link>
                        <Link to="/contact" className="text-white hover:text-orange-300 transition-colors">
                            {t('header.contact_us')}
                        </Link>

                        {/* Language Selector */}
                        <div className="relative">
                            <button
                                onClick={toggleLanguageMenu}
                                className="flex items-center gap-1 text-white hover:text-orange-300 transition-colors"
                                aria-haspopup="true"
                                aria-expanded={isLanguageMenuOpen}
                                aria-label="Выбор языка"
                            >
                                <Globe size={16} />
                                <span>{i18n.language.toUpperCase()}</span>
                                <ChevronDown size={16} className={`transition-transform ${isLanguageMenuOpen ? 'rotate-180' : ''}`} />
                            </button>

                            {isLanguageMenuOpen && (
                                <div
                                    className="absolute right-0 mt-2 py-2 w-40 bg-gray-900 border border-gray-800 rounded-lg shadow-xl z-10"
                                    role="menu"
                                >
                                    {Object.keys(supportedLanguages).map((lng) => (
                                        <button
                                            key={lng}
                                            onClick={() => changeLanguage(lng)}
                                            className={`w-full text-left px-4 py-2 hover:bg-gray-800 ${
                                                i18n.language === lng ? 'text-orange-500' : 'text-white'
                                            }`}
                                            role="menuitem"
                                        >
                                            {supportedLanguages[lng]}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                        <button
                            onClick={() => handleAuthClick('login')}
                            className="text-white hover:text-orange-300 transition-colors"
                            aria-label={t('header.login')}
                        >
                            {t('header.login')}
                        </button>
                        <button
                            onClick={() => handleAuthClick('register')}
                            className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-full transition-all transform hover:scale-105"
                            aria-label={t('header.sign_up')}
                        >
                            {t('header.sign_up')}
                        </button>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden text-white"
                        onClick={toggleMenu}
                        aria-label={isMenuOpen ? "Закрыть меню" : "Открыть меню"}
                        aria-expanded={isMenuOpen}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>

                {/* Mobile Navigation */}
                {isMenuOpen && (
                    <div
                        className="md:hidden bg-gray-900/95 backdrop-blur-lg"
                        role="navigation"
                    >
                        <div className="container mx-auto px-6 py-4 flex flex-col">
                            <Link to="/courses" className="text-white py-3 border-b border-gray-800" onClick={toggleMenu}>
                                {t('header.courses')}
                            </Link>
                            <Link to="/about" className="text-white py-3 border-b border-gray-800" onClick={toggleMenu}>
                                {t('header.about')}
                            </Link>
                            <Link to="/contact" className="text-white py-3 border-b border-gray-800" onClick={toggleMenu}>
                                {t('header.contact_us')}
                            </Link>

                            {/* Language Selector in Mobile Menu */}
                            <div className="py-3 border-b border-gray-800">
                                <div className="flex flex-col">
                                    <button
                                        onClick={toggleLanguageMenu}
                                        className="flex items-center gap-1 text-white"
                                        aria-haspopup="true"
                                        aria-expanded={isLanguageMenuOpen}
                                        aria-label="Выбор языка"
                                    >
                                        <Globe size={16} />
                                        <span>{currentLanguage}</span>
                                        <ChevronDown size={16} className={`ml-1 transition-transform ${isLanguageMenuOpen ? 'rotate-180' : ''}`} />
                                    </button>

                                    {isLanguageMenuOpen && (
                                        <div
                                            className="mt-2 ml-5 flex flex-col"
                                            role="menu"
                                        >
                                            {Object.keys(supportedLanguages).map((lng) => (
                                                <button
                                                    key={lng}
                                                    onClick={() => changeLanguage(lng)}
                                                    className={`text-left py-2 ${
                                                        i18n.language === lng ? 'text-orange-500' : 'text-white'
                                                    }`}
                                                    role="menuitem"
                                                >
                                                    {supportedLanguages[lng]}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col gap-3 mt-4">
                                <button
                                    onClick={() => {
                                        handleAuthClick('login');
                                        toggleMenu();
                                    }}
                                    className="text-white py-2"
                                    aria-label={t('header.login')}
                                >
                                    {t('header.login')}
                                </button>
                                <button
                                    onClick={() => {
                                        handleAuthClick('register');
                                        toggleMenu();
                                    }}
                                    className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-3 rounded-full transition-colors"
                                    aria-label={t('header.sign_up')}
                                >
                                    {t('header.sign_up')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </header>

            <AuthDialog
                open={showAuthDialog}
                onClose={() => setShowAuthDialog(false)}
                initialMode={initialAuthMode}
                onSuccess={(token) => {
                    setShowAuthDialog(false);
                    onLoginSuccess && onLoginSuccess(token);
                }}
            />
        </>
    );
};

export default LandingHeader;