import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RouteParamsHandler from './RouteParamsHandler';
import { motion } from 'framer-motion';
import AceEditor from 'react-ace';
import {
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Paper,
    Alert,
    Switch,
    Chip,
    IconButton,
    Tooltip,
    CircularProgress,
    Tabs,
    Tab,
    InputAdornment
} from '@mui/material';
import {
    PlayArrow as PlayArrowIcon,
    ContentCopy as CopyIcon,
    Close as CloseIcon,
    History as HistoryIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    KeyboardArrowDown as ArrowDownIcon,
    KeyboardArrowUp as ArrowUpIcon
} from '@mui/icons-material';

// ACE editor imports
import 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

// Утилиты для стилизации методов
const getMethodColor = (method) => {
    switch (method) {
        case 'GET':
            return '#4CAF50';
        case 'POST':
            return '#2196F3';
        case 'PUT':
            return '#FF9800';
        case 'PATCH':
            return '#FF9800';
        case 'DELETE':
            return '#F44336';
        default:
            return '#757575';
    }
};

const RestTab = ({ activeComponent }) => {
    const { t } = useTranslation();
    const [method, setMethod] = useState('GET');
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('');
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEndpoint, setSelectedEndpoint] = useState(null);
    const [jsonError, setJsonError] = useState(null);
    const [formatOnSend, setFormatOnSend] = useState(true);
    const [history, setHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [copiedField, setCopiedField] = useState(null);
    const [showParams, setShowParams] = useState(false);
    const [queryParams, setQueryParams] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [activeTab, setActiveTab] = useState(0); // 0 - Body, 1 - Params, 2 - Headers

    // Эффект для установки URL при изменении активного компонента
    useEffect(() => {
        if (activeComponent?.host) {
            setUrl(activeComponent.host);
        }
    }, [activeComponent]);

    // Копирование в буфер обмена
    const copyToClipboard = (text, fieldName) => {
        navigator.clipboard.writeText(text);
        setCopiedField(fieldName);
        setTimeout(() => setCopiedField(null), 2000);
    };

    // Функция валидации JSON
    const validateJson = (jsonString) => {
        try {
            if (jsonString.trim()) {
                JSON.parse(jsonString);
                setJsonError(null);
                return true;
            }
            return true;
        } catch (error) {
            setJsonError(error.message);
            return false;
        }
    };

    // Обработчик изменения тела запроса
    const handleRequestBodyChange = (value) => {
        setRequestBody(value);
        validateJson(value);
    };

    // Форматирование JSON
    const formatJson = () => {
        try {
            const parsed = JSON.parse(requestBody);
            setRequestBody(JSON.stringify(parsed, null, 2));
            setJsonError(null);
        } catch (error) {
            setJsonError('Cannot format invalid JSON');
        }
    };

    // Обработчик выбора эндпоинта
    const handleEndpointSelect = (endpoint) => {
        setSelectedEndpoint(endpoint);
        setUrl(`${activeComponent.host}${endpoint.route}`);
        setMethod(endpoint.methods[0]);
        setJsonError(null);

        // Генерация примера тела запроса для POST/PUT/PATCH
        if (['POST', 'PUT', 'PATCH'].includes(endpoint.methods[0]) && endpoint.inputs) {
            const requestExample = {};

            // Находим описание сущности в конфигурации
            const entityConfig = activeComponent?.configuration?.entities?.find(
                e => e.name === endpoint.entity
            );

            if (entityConfig) {
                endpoint.inputs.forEach(input => {
                    // Проверяем входной параметр на тип json или соответствие имени сущности
                    if (input.type.toLowerCase() === 'json' || input.type === endpoint.entity) {
                        const entityExample = {};
                        entityConfig.fields.forEach(field => {
                            // Пропускаем primary key для POST запросов
                            if (endpoint.methods[0] === 'POST' && field.primary_key) {
                                return;
                            }

                            switch (field.type.toLowerCase()) {
                                case 'string':
                                    if (field.name.toLowerCase().includes('name')) {
                                        entityExample[field.name] = `Example ${field.name.replace('_', ' ')}`;
                                    } else if (field.name.toLowerCase().includes('date')) {
                                        entityExample[field.name] = new Date().toISOString().split('T')[0];
                                    } else {
                                        entityExample[field.name] = `Value for ${field.name}`;
                                    }
                                    break;
                                case 'integer':
                                    if (field.name.toLowerCase().includes('quantity')) {
                                        entityExample[field.name] = 100;
                                    } else if (field.primary_key) {
                                        entityExample[field.name] = 1;
                                    } else {
                                        entityExample[field.name] = 42;
                                    }
                                    break;
                                case 'float':
                                case 'decimal':
                                    entityExample[field.name] = 123.45;
                                    break;
                                case 'boolean':
                                    entityExample[field.name] = true;
                                    break;
                                case 'date':
                                    entityExample[field.name] = new Date().toISOString().split('T')[0];
                                    break;
                                case 'datetime':
                                case 'timestamp':
                                    entityExample[field.name] = new Date().toISOString();
                                    break;
                                case 'json':
                                case 'array':
                                    entityExample[field.name] = [];
                                    break;
                                default:
                                    entityExample[field.name] = null;
                            }
                        });

                        requestExample[input.name] = entityExample;
                    } else {
                        // Для не-entity inputs используем простые примеры значений
                        switch (input.type.toLowerCase()) {
                            case 'integer':
                                requestExample[input.name] = 1;
                                break;
                            case 'string':
                                requestExample[input.name] = `Example ${input.name}`;
                                break;
                            default:
                                requestExample[input.name] = null;
                        }
                    }
                });

                setRequestBody(JSON.stringify(requestExample, null, 2));
            }
        } else {
            setRequestBody('');
        }
    };

    // Отправка запроса
    const handleSendRequest = async () => {
        if (!url) return;

        // Собираем query параметры
        const enabledQueryParams = queryParams
            .filter(p => p.enabled && p.key)
            .reduce((acc, p) => {
                acc[p.key] = p.value;
                return acc;
            }, {});

        // Собираем заголовки
        const enabledHeaders = headers
            .filter(h => h.enabled && h.key)
            .reduce((acc, h) => {
                acc[h.key] = h.value;
                return acc;
            }, {});

        // Формируем URL с query параметрами
        const urlWithParams = new URL(url);
        Object.entries(enabledQueryParams).forEach(([key, value]) => {
            urlWithParams.searchParams.append(key, value);
        });

        setIsLoading(true);
        const startTime = Date.now();

        try {
            const options = {
                method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    ...enabledHeaders
                },
                mode: 'cors',
                insecure: true,
            };

            if (method !== 'GET' && method !== 'DELETE' && requestBody) {
                options.body = requestBody;
            }

            const res = await fetch(urlWithParams.toString(), options);
            const data = await res.json();
            const endTime = Date.now();

            setResponse({
                status: res.status,
                statusText: res.statusText,
                headers: Object.fromEntries(res.headers.entries()),
                data,
                time: endTime - startTime
            });

            // Добавляем в историю
            setHistory(prev => [{
                method,
                url,
                body: requestBody,
                response: data,
                status: res.status,
                timestamp: new Date(),
                time: endTime - startTime
            }, ...prev].slice(0, 10));

        } catch (error) {
            setResponse({
                error: error.message,
                time: Date.now() - startTime
            });
        } finally {
            setIsLoading(false);
        }
    };

    const endpoints = activeComponent?.configuration?.endpoints || [];

    return (
        <div className="p-4">
            <div className="grid gap-2">
                {/* Секция эндпоинтов */}
                <Paper elevation={0} className="bg-gray-100 p-3 rounded-lg shadow-sm">
                    <Typography variant="subtitle2" className="text-gray-700 mb-2">
                        {t('rest_tab.available_endpoints')}
                    </Typography>
                    <div className="grid gap-1">
                        {endpoints.map((endpoint, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.01 }}
                                className={`p-2 rounded cursor-pointer bg-white hover:bg-gray-50 transition-colors ${
                                    selectedEndpoint === endpoint ? 'ring-2 ring-blue-500' : ''
                                }`}
                                onClick={() => handleEndpointSelect(endpoint)}
                            >
                                <div className="flex items-center gap-2">
                                    <div className="flex gap-1">
                                        {endpoint.methods.map(m => (
                                            <Chip
                                                key={m}
                                                label={m}
                                                size="small"
                                                sx={{
                                                    backgroundColor: getMethodColor(m),
                                                    color: 'white',
                                                    height: '20px',
                                                    fontSize: '0.7rem',
                                                    fontWeight: 'bold',
                                                    '& .MuiChip-label': {
                                                        padding: '0 6px'
                                                    }
                                                }}
                                            />
                                        ))}
                                    </div>
                                    <Typography className="font-mono text-sm">
                                        {endpoint.route}
                                    </Typography>
                                    {endpoint.description && (
                                        <Typography variant="caption" className="text-gray-500">
                                            • {endpoint.description}
                                        </Typography>
                                    )}
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </Paper>

                {/* Основная панель запроса */}
                <Paper elevation={0} className="bg-gray-100 p-3 rounded-lg shadow-sm">
                    {/* URL и методы */}
                    <div className="flex gap-2 items-center">
                        <Select
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            size="small"
                            className="w-24 bg-white"
                            sx={{
                                height: '32px',
                                '& .MuiSelect-select': {
                                    padding: '4px 8px',
                                    fontSize: '0.85rem'
                                }
                            }}
                        >
                            <MenuItem value="GET">GET</MenuItem>
                            <MenuItem value="POST">POST</MenuItem>
                            <MenuItem value="PUT">PUT</MenuItem>
                            <MenuItem value="PATCH">PATCH</MenuItem>
                            <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>

                        <TextField
                            fullWidth
                            size="small"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder={t('rest_tab.enter_url')}
                            className="bg-white rounded"
                            sx={{
                                '& .MuiInputBase-root': { height: '32px' },
                                '& .MuiInputBase-input': {
                                    padding: '4px 12px',
                                    fontSize: '0.85rem'
                                }
                            }}
                            InputProps={{
                                endAdornment: url && (
                                    <InputAdornment position="end">
                                        <Tooltip title={t('rest_tab.copy_url')}>
                                            <IconButton
                                                size="small"
                                                edge="end"
                                                onClick={() => copyToClipboard(url, 'URL')}
                                                sx={{ padding: '4px' }}
                                            >
                                                <CopyIcon sx={{ fontSize: '1.2rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <RouteParamsHandler
                            url={url}
                            onUrlChange={(processedUrl) => setUrl(processedUrl)}
                        />

                        <div className="flex gap-1">
                            <Tooltip title={t('rest_tab.request_history')}>
                                <IconButton
                                    size="small"
                                    onClick={() => setShowHistory(!showHistory)}
                                    sx={{
                                        height: '32px',
                                        width: '32px',
                                        backgroundColor: showHistory ? 'rgba(0, 0, 0, 0.05)' : 'transparent'
                                    }}
                                >
                                    <HistoryIcon sx={{ fontSize: '1.2rem' }} />
                                </IconButton>
                            </Tooltip>

                            <Button
                                variant="contained"
                                disabled={isLoading || !url}
                                onClick={handleSendRequest}
                                sx={{
                                    height: '32px',
                                    fontSize: '0.85rem',
                                    textTransform: 'none',
                                    padding: '0 16px',
                                    minWidth: '100px'
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress size={16} color="inherit" />
                                ) : (
                                    <>
                                        <PlayArrowIcon sx={{ mr: 1 }} />
                                        {t('rest_tab.send')}
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>

                    {/* История запросов */}
                    {showHistory && (
                        <Paper elevation={0} className="bg-gray-100 p-3 rounded-lg mt-2">
                            <div className="flex justify-between items-center mb-2">
                                <Typography variant="subtitle2" className="font-medium text-gray-700">
                                    {t('rest_tab.request_history')}
                                </Typography>
                                <IconButton size="small" onClick={() => setShowHistory(false)}>
                                    <CloseIcon sx={{ fontSize: '1.2rem' }} />
                                </IconButton>
                            </div>
                            <div className="space-y-2">
                                {history.map((item, index) => (
                                    <Paper
                                        key={index}
                                        className="p-2 hover:bg-gray-50 cursor-pointer bg-white"
                                        onClick={() => {
                                            setMethod(item.method);
                                            setUrl(item.url);
                                            setRequestBody(item.body || '');
                                            setShowHistory(false);
                                        }}
                                    >
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center gap-2">
                                                <Chip
                                                    label={item.method}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: getMethodColor(item.method),
                                                        color: 'white',
                                                        height: '20px',
                                                        fontSize: '0.75rem',
                                                        '& .MuiChip-label': { padding: '0 6px' }
                                                    }}
                                                />
                                                <Typography variant="caption" className="text-gray-500">
                                                    {new Date(item.timestamp).toLocaleTimeString()}
                                                </Typography>
                                            </div>
                                            <div className="flex gap-2">
                                                <Chip
                                                    label={`${item.status}`}
                                                    size="small"
                                                    color={item.status >= 400 ? 'error' : 'success'}
                                                    sx={{
                                                        height: '20px',
                                                        fontSize: '0.75rem',
                                                        '& .MuiChip-label': { padding: '0 6px' }
                                                    }}
                                                />
                                                {item.time && (
                                                    <Chip
                                                        label={`${item.time}ms`}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            height: '20px',
                                                            fontSize: '0.75rem',
                                                            '& .MuiChip-label': { padding: '0 6px' }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <Typography variant="body2" className="font-mono truncate text-gray-700 mt-1">
                                            {item.url}
                                        </Typography>
                                    </Paper>
                                ))}
                                {history.length === 0 && (
                                    <Typography className="text-center text-gray-500 py-4">
                                        {t('rest_tab.request_history_empty')}
                                    </Typography>
                                )}
                            </div>
                        </Paper>
                    )}

                    {/* Параметры и заголовки */}
                    <div className="mt-2">
                        <Button
                            size="small"
                            endIcon={showParams ? <ArrowUpIcon /> : <ArrowDownIcon />}
                            onClick={() => setShowParams(!showParams)}
                            sx={{
                                fontSize: '0.85rem',
                                textTransform: 'none',
                                color: 'gray'
                            }}
                        >
                            {t('rest_tab.params_headers')}
                        </Button>

                        {showParams && (
                            <Paper elevation={0} className="bg-white mt-2 p-3">
                                <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                                    <Tab label={t('rest_tab.query_parameters')} />
                                    <Tab label={t('rest_tab.headers')} />
                                </Tabs>

                                <div className="mt-3">
                                    {activeTab === 0 && selectedEndpoint?.query_parameters && (
                                        <div className="mb-2 text-sm text-gray-600">
                                            <Typography variant="caption">
                                                {t('rest_tab.available_parameters')}
                                            </Typography>
                                            <div className="mt-1 space-y-1">
                                                {selectedEndpoint.query_parameters.map((param, idx) => (
                                                    <div key={idx} className="flex items-center gap-2">
                                                        <Chip
                                                            label={param.name}
                                                            size="small"
                                                            variant="outlined"
                                                            color="primary"
                                                        />
                                                        <Typography variant="caption" className="text-gray-500">
                                                            {param.description}
                                                            {param.required && (
                                                                <span className="text-red-500 ml-1">*</span>
                                                            )}
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex justify-end mb-2">
                                        <Button
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                const newParams = activeTab === 0 ? queryParams : headers;
                                                const setParams = activeTab === 0 ? setQueryParams : setHeaders;

                                                // Добавляем подсказку из конфигурации если это query параметр
                                                if (activeTab === 0 && selectedEndpoint?.query_parameters?.length > 0) {
                                                    const suggestionParam = selectedEndpoint.query_parameters[0];
                                                    setParams([...newParams, {
                                                        key: suggestionParam.name,
                                                        value: '',
                                                        enabled: true,
                                                        description: suggestionParam.description
                                                    }]);
                                                } else {
                                                    setParams([...newParams, { key: '', value: '', enabled: true }]);
                                                }
                                            }}
                                        >
                                            {t('rest_tab.add_parameter')}
                                        </Button>
                                    </div>
                                    <div className="space-y-1">
                                        {(activeTab === 0 ? queryParams : headers).map((param, index) => (
                                            <div key={index} className="flex items-center gap-1">
                                                <Switch
                                                    size="small"
                                                    checked={param.enabled}
                                                    onChange={(e) => {
                                                        const setParams = activeTab === 0 ? setQueryParams : setHeaders;
                                                        const newParams = [...(activeTab === 0 ? queryParams : headers)];
                                                        newParams[index].enabled = e.target.checked;
                                                        setParams(newParams);
                                                    }}
                                                    sx={{
                                                        padding: 0,
                                                        '& .MuiSwitch-switchBase': {
                                                            padding: 4
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    size="small"
                                                    placeholder={t('rest_tab.param_name')}
                                                    value={param.key}
                                                    onChange={(e) => {
                                                        const setParams = activeTab === 0 ? setQueryParams : setHeaders;
                                                        const newParams = [...(activeTab === 0 ? queryParams : headers)];
                                                        newParams[index].key = e.target.value;
                                                        setParams(newParams);
                                                    }}
                                                    className="flex-1"
                                                    disabled={!param.enabled}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '4px 8px',
                                                            fontSize: '0.85rem'
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    size="small"
                                                    placeholder={t('rest_tab.param_value')}
                                                    value={param.value}
                                                    onChange={(e) => {
                                                        const setParams = activeTab === 0 ? setQueryParams : setHeaders;
                                                        const newParams = [...(activeTab === 0 ? queryParams : headers)];
                                                        newParams[index].value = e.target.value;
                                                        setParams(newParams);
                                                    }}
                                                    className="flex-1"
                                                    disabled={!param.enabled}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            padding: '4px 8px',
                                                            fontSize: '0.85rem'
                                                        }
                                                    }}
                                                />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        const setParams = activeTab === 0 ? setQueryParams : setHeaders;
                                                        const newParams = [...(activeTab === 0 ? queryParams : headers)].filter((_, i) => i !== index);
                                                        setParams(newParams);
                                                    }}
                                                    sx={{ padding: '4px' }}
                                                >
                                                    <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Paper>
                        )}
                    </div>

                    {/* Тело запроса */}
                    {(method === 'POST' || method === 'PUT' || method === 'PATCH') && (
                        <div className="mt-2">
                            <div className="flex justify-between items-center mb-2">
                                <Typography variant="body2" className="text-gray-600">
                                    {t('rest_tab.request_body')}
                                </Typography>
                                <div className="flex items-center gap-2">
                                    <Switch
                                        size="small"
                                        checked={formatOnSend}
                                        onChange={(e) => setFormatOnSend(e.target.checked)}
                                        sx={{
                                            padding: 0,
                                            '& .MuiSwitch-switchBase': {
                                                padding: 4
                                            }
                                        }}
                                    />
                                    <Typography variant="caption" className="text-gray-500">
                                        {t('rest_tab.format_on_send')}
                                    </Typography>
                                    <Button
                                        size="small"
                                        onClick={formatJson}
                                        disabled={!requestBody}
                                        sx={{
                                            fontSize: '0.85rem',
                                            textTransform: 'none'
                                        }}
                                    >
                                        {t('rest_tab.format')}
                                    </Button>
                                </div>
                            </div>

                            {jsonError && (
                                <Alert severity="error" className="mb-2 text-sm">
                                    {jsonError}
                                </Alert>
                            )}

                            <Paper elevation={0} className="bg-white">
                                <AceEditor
                                    mode="json"
                                    theme="tomorrow"
                                    value={requestBody}
                                    onChange={handleRequestBodyChange}
                                    name="request-body-editor"
                                    width="100%"
                                    height="200px"
                                    fontSize={13}
                                    showPrintMargin={false}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </Paper>
                        </div>
                    )}

                    {/* Ответ */}
                    {response && (
                        <div className="mt-2">
                            <div className="flex justify-between items-center">
                                <Typography variant="body2" className="text-gray-600">
                                    {t('rest_tab.response')}
                                </Typography>
                                <div className="flex items-center gap-2">
                                    <Tooltip title={t('rest_tab.copy_response')}>
                                        <IconButton
                                            size="small"
                                            onClick={() => copyToClipboard(
                                                JSON.stringify(response.data || response, null, 2),
                                                t('rest_tab.response')
                                            )}
                                            sx={{padding: '4px'}}
                                        >
                                            <CopyIcon sx={{fontSize: '1.2rem'}}/>
                                        </IconButton>
                                    </Tooltip>
                                    {response.status && (
                                        <Chip
                                            label={`${response.status} ${response.statusText}`}
                                            size="small"
                                            color={response.status >= 400 ? 'error' : 'success'}
                                            sx={{
                                                height: '20px',
                                                fontSize: '0.75rem',
                                                '& .MuiChip-label': {padding: '0 6px'}
                                            }}
                                        />
                                    )}
                                    {response.time && (
                                        <Chip
                                            label={`${response.time}ms`}
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                                height: '20px',
                                                fontSize: '0.75rem',
                                                '& .MuiChip-label': {padding: '0 6px'}
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <Paper elevation={0} className="bg-white mt-1">
                                <AceEditor
                                    mode="json"
                                    theme="tomorrow"
                                    value={JSON.stringify(response.data || response, null, 2)}
                                    readOnly
                                    width="100%"
                                    height="200px"
                                    fontSize={13}
                                    showPrintMargin={false}
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </Paper>
                        </div>
                    )}
                </Paper>

                {/* Уведомление о копировании */}
                {copiedField && (
                    <motion.div
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 20}}
                        className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded shadow-lg z-50"
                    >
                        {t('rest_tab.field_copied', { field: copiedField })}
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default RestTab;