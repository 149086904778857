// src/pages/Contact.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">{t('contact.title')}</h1>
            <p className="text-gray-900 mb-4">
                {t('contact.description')}
            </p>
            <p className="text-gray-900 font-semibold">
                {t('contact.email_prefix')}{" "}
                <a
                href="mailto:info@dvakota.com"
                className="text-blue-500 underline hover:text-blue-700"
                >
                echinoidos@gmail.com
            </a>
        </p>
    <p className="text-gray-800 mt-4">{t('contact.response_note')}</p>
</div>
);
};

export default Contact;