// src/pages/About.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">{t('about.title')}</h1>
            <div className=" p-4 rounded">
                <p className="text-gray-900">
                    {t('about.description1')}
                </p>
                <p className="mt-4 text-gray-800">
                    {t('about.description2')}
                </p>
            </div>
        </div>
    );
};

export default About;