import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import AceEditor from 'react-ace';
import { parse } from 'graphql';
import {
    Paper,
    Typography,
    Button,
    Tabs,
    Tab,
    Tooltip,
    Chip,
    IconButton,
} from '@mui/material';
import {
    PlayArrow,
    History,
    BookOutlined,
    Save,
    FolderOpen,
    Close,
    ContentCopy as CopyIcon,
} from '@mui/icons-material';

// ACE editor imports
import 'ace-builds/src-min-noconflict/mode-graphqlschema';
import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-tomorrow';
import 'ace-builds/src-min-noconflict/ext-language_tools';

// Импортируем вспомогательные компоненты и утилиты
import {
    SchemaExplorer,
    SavedQueries,
    SaveQueryDialog,
    generateQueryExample,
    extractVariablesFromQuery
} from './graphql-utils';

const GraphQLTab = ({ activeComponent }) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState(null);
    const [variables, setVariables] = useState('{}');
    const [activeTab, setActiveTab] = useState(0);
    const [showDocs, setShowDocs] = useState(true);
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [queryError, setQueryError] = useState(null);
    const [showSavedQueries, setShowSavedQueries] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [copiedField, setCopiedField] = useState(null);

    // Инициализация начального запроса
    useEffect(() => {
        if (activeComponent?.configuration?.entities?.[0]) {
            const entity = activeComponent.configuration.entities[0];
            setQuery(generateQueryExample(entity));
        }
    }, [activeComponent]);

    // Валидация запроса
    const validateQuery = useCallback((queryString) => {
        try {
            parse(queryString);
            setQueryError(null);
            return true;
        } catch (error) {
            setQueryError(error.message);
            return false;
        }
    }, []);

    // Обработчик изменения запроса
    const handleQueryChange = useCallback((newQuery) => {
        setQuery(newQuery);
        validateQuery(newQuery);
        const newVariables = extractVariablesFromQuery(newQuery);
        setVariables(newVariables);
    }, [validateQuery]);

    // Копирование в буфер обмена
    const copyToClipboard = (text, fieldName) => {
        navigator.clipboard.writeText(text);
        setCopiedField(fieldName);
        setTimeout(() => setCopiedField(null), 2000);
    };

    // Выполнение запроса
    const executeQuery = async () => {
        if (!activeComponent?.host) return;

        setIsLoading(true);
        try {
            const response = await fetch(`${activeComponent.host}/graphql`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query,
                    variables: JSON.parse(variables)
                })
            });
            const data = await response.json();
            setResponse(data);

            // Добавляем в историю
            setHistory(prev => [
                {
                    query,
                    variables,
                    response: data,
                    timestamp: new Date()
                },
                ...prev
            ].slice(0, 10));

        } catch (error) {
            setResponse({ errors: [{ message: error.message }] });
        } finally {
            setIsLoading(false);
        }
    };

    // Сохранение запроса
    const handleSaveQuery = (queryData) => {
        const savedQueries = JSON.parse(localStorage.getItem('graphqlQueries') || '[]');
        savedQueries.push(queryData);
        localStorage.setItem('graphqlQueries', JSON.stringify(savedQueries));
    };

    // Обработчики для кастомных вкладок
    const getTabContent = (index) => {
        switch (index) {
            case 0: // Query Tab
                return (
                    <Paper elevation={0} className="bg-white">
                        <AceEditor
                            mode="graphqlschema"
                            theme="tomorrow"
                            value={query}
                            onChange={handleQueryChange}
                            name="graphql-query"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height="300px"
                            fontSize={14}
                            showPrintMargin={false}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 2,
                            }}
                        />
                    </Paper>
                );
            case 1: // Variables Tab
                return (
                    <Paper elevation={0} className="bg-white">
                        <AceEditor
                            mode="json"
                            theme="tomorrow"
                            value={variables}
                            onChange={setVariables}
                            name="variables-editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height="300px"
                            fontSize={14}
                            showPrintMargin={false}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 2,
                            }}
                        />
                    </Paper>
                );
            case 2: // History Tab
                return (
                    <div className="space-y-2">
                        {history.map((item, index) => (
                            <Paper
                                key={index}
                                className="p-3 hover:bg-gray-50 cursor-pointer bg-white"
                                onClick={() => {
                                    setQuery(item.query);
                                    setVariables(item.variables);
                                    setActiveTab(0);
                                }}
                            >
                                <div className="flex justify-between items-center mb-2">
                                    <Typography variant="caption" className="text-gray-500">
                                        {new Date(item.timestamp).toLocaleString()}
                                    </Typography>
                                    <Chip
                                        size="small"
                                        label={item.response.errors ? t('graphql_tab.error') : t('graphql_tab.success')}
                                        color={item.response.errors ? 'error' : 'success'}
                                    />
                                </div>
                                <Typography variant="body2" className="font-mono truncate">
                                    {item.query.split('\n')[0]}
                                </Typography>
                            </Paper>
                        ))}
                        {history.length === 0 && (
                            <Typography className="text-gray-500 text-center py-4">
                                {t('graphql_tab.empty_history')}
                            </Typography>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    if (!activeComponent || activeComponent.type_name !== 'Microservice') {
        return (
            <div className="p-4 flex items-center justify-center h-full">
                <Paper elevation={0} className="p-8 text-center">
                    <Typography variant="h6" color="textSecondary" className="mb-2">
                        {t('graphql_tab.select_microservice')}
                    </Typography>
                </Paper>
            </div>
        );
    }

    return (
        <div className="p-4">
            <div className="flex gap-4">
                {/* Основная панель с редактором */}
                <div className="flex-grow">
                    <Paper elevation={0} className="bg-gray-100 p-4 rounded-lg shadow-md">
                        {/* Верхняя панель с кнопками */}
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex gap-2">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={executeQuery}
                                    disabled={isLoading || !!queryError}
                                    startIcon={<PlayArrow />}
                                >
                                    {isLoading ? t('graphql_tab.executing') : t('graphql_tab.execute')}
                                </Button>
                                <div className="flex items-center gap-2 border-l pl-3">
                                    <Tooltip title={t('graphql_tab.save_query')}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setSaveDialogOpen(true)}
                                            className="text-gray-600 hover:text-primary"
                                        >
                                            <Save />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('graphql_tab.load_query')}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setShowSavedQueries(true)}
                                            className="text-gray-600 hover:text-primary"
                                        >
                                            <FolderOpen />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('graphql_tab.request_history')}>
                                        <IconButton
                                            size="small"
                                            onClick={() => setActiveTab(2)}
                                            className="text-gray-600 hover:text-primary"
                                        >
                                            <History />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <Chip
                                    label={`${t('graphql_tab.endpoint')} ${activeComponent?.host || 'Not set'}`}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => copyToClipboard(activeComponent?.host, 'endpoint')}
                                    icon={<CopyIcon fontSize="small" />}
                                />
                                <Tooltip title={t('graphql_tab.documentation')}>
                                    <IconButton
                                        size="small"
                                        onClick={() => setShowDocs(!showDocs)}
                                        className={`text-gray-600 hover:text-primary ${showDocs ? 'bg-blue-50' : ''}`}
                                    >
                                        <BookOutlined />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>

                        {/* Ошибки запроса */}
                        {queryError && (
                            <Paper className="mb-4 p-3 bg-red-50">
                                <div className="flex items-center gap-2">
                                    <Typography color="error" className="text-sm">
                                        {queryError}
                                    </Typography>
                                </div>
                            </Paper>
                        )}

                        {/* Вкладки */}
                        <Tabs
                            value={activeTab}
                            onChange={(_, newValue) => setActiveTab(newValue)}
                            className="mb-2"
                        >
                            <Tab label={t('graphql_tab.query')} />
                            <Tab label={t('graphql_tab.variables')} />
                            <Tab label={t('graphql_tab.history')} />
                        </Tabs>

                        {/* Содержимое активной вкладки */}
                        {getTabContent(activeTab)}

                        {/* Результат запроса */}
                        {response && (
                            <div className="mt-4">
                                <Typography variant="subtitle2" className="mb-2 font-medium text-gray-700">
                                    {t('graphql_tab.result')}
                                </Typography>
                                <Paper elevation={0} className="bg-white">
                                    <AceEditor
                                        mode="json"
                                        theme="tomorrow"
                                        value={JSON.stringify(response, null, 2)}
                                        readOnly
                                        name="response-editor"
                                        editorProps={{ $blockScrolling: true }}
                                        width="100%"
                                        height="200px"
                                        fontSize={14}
                                        showPrintMargin={false}
                                        setOptions={{
                                            showLineNumbers: true,
                                            tabSize: 2,
                                        }}
                                    />
                                </Paper>
                            </div>
                        )}
                    </Paper>
                </div>

                {/* Документация */}
                <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    animate={{
                        width: showDocs ? 384 : 0,
                        opacity: showDocs ? 1 : 0
                    }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                    style={{ maxHeight: 'calc(100vh - 2rem)' }} // Добавляем максимальную высоту
                >
                    {showDocs && (
                        <Paper elevation={0} className="bg-gray-100 p-4 rounded-lg shadow-md h-full overflow-hidden">
                            <div className="flex justify-between items-center mb-4 sticky top-0 bg-gray-100 z-10">
                                <Typography variant="subtitle1" className="font-semibold text-gray-700">
                                    {t('graphql_tab.documentation')}
                                </Typography>
                                <IconButton size="small" onClick={() => setShowDocs(false)}>
                                    <Close />
                                </IconButton>
                            </div>
                            <div className="space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 8rem)' }}>
                                {activeComponent?.configuration?.entities.map((entity, index) => (
                                    <SchemaExplorer
                                        key={index}
                                        entity={entity}
                                        onExampleSelect={(example) => {
                                            setQuery(example);
                                            setActiveTab(0);
                                        }}
                                    />
                                ))}
                            </div>
                        </Paper>
                    )}
                </motion.div>
            </div>

            {/* Модальные окна */}
            {showSavedQueries && (
                <SavedQueries
                    onSelect={(savedQuery) => {
                        setQuery(savedQuery.query);
                        setShowSavedQueries(false);
                    }}
                    onClose={() => setShowSavedQueries(false)}
                />
            )}

            <SaveQueryDialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                onSave={handleSaveQuery}
                query={query}
            />

            {/* Уведомление о копировании */}
            {copiedField && (
                <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded shadow-lg">
                    {t('graphql_tab.field_copied', { field: copiedField })}
                </div>
            )}
        </div>
    );
};

export default GraphQLTab;