import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { getProcessStage } from '../services/apiService';
import animationData from '../img/generator_loader.json';

const GenerationLoader = ({ token, processId, sandboxId }) => {
    const { t } = useTranslation();
    const [stage, setStage] = useState(1);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const processStage = await getProcessStage(token, processId);

                if (processStage && processStage.length > 0) {
                    const currentStage = processStage[0].stage;
                    setStage(currentStage);

                    // Проверяем завершение процесса
                    if (currentStage >= 2) {
                        clearInterval(interval);
                        // Используем navigate вместо window.location
                        navigate(`/sandbox/${sandboxId}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching generation stage:", error);
                setError(t('generation_loader.error'));
                clearInterval(interval);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [token, processId, navigate, sandboxId, t]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData || {},
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    // Если есть ошибка, показываем её
    if (error) {
        return (
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-white opacity-50"></div>
                <div className="relative z-10 bg-white p-6 rounded-lg shadow-xl">
                    <p className="text-red-600 mb-4">{error}</p>
                    <button
                        onClick={() => navigate('/')}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        {t('generation_loader.return_home')}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-white opacity-50 pointer-events-none"></div>
            <div className="relative z-10 text-center text-gray-800">
                <div className="mb-4 text-xl bg-white bg-opacity-75 px-4 py-2 inline-block rounded-md">
                    {t(`generation_loader.stages.${stage}`)}
                </div>
                <Lottie options={defaultOptions} height={400} width={400} />
            </div>
        </div>
    );
};

export default GenerationLoader;