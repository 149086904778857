// src/components/courses/CourseFilters.js
import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CourseFilters = ({ categories, selectedCategory, onCategoryChange, priceFilter, onPriceFilterChange }) => {
    const { t } = useTranslation();

    const selectStyle = {
        fontSize: '0.9rem',
        '& .MuiSelect-select': {
            paddingTop: '2px',
            paddingBottom: '2px',
        },
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: '0.9rem' }}>{t('courses.filters.category')}</InputLabel>
                <Select
                    value={selectedCategory}
                    onChange={onCategoryChange}
                    label={t('courses.filters.category')}
                    sx={selectStyle}
                >
                    <MenuItem value="">{t('courses.filters.all_categories')}</MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: '0.9rem' }}>{t('courses.filters.price')}</InputLabel>
                <Select
                    value={priceFilter}
                    onChange={onPriceFilterChange}
                    label={t('courses.filters.price')}
                    sx={selectStyle}
                >
                    <MenuItem value="all">{t('courses.filters.all_courses')}</MenuItem>
                    <MenuItem value="free">{t('courses.filters.free')}</MenuItem>
                    <MenuItem value="paid">{t('courses.filters.paid')}</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default CourseFilters;