import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Code, Brain, Globe, Database, Puzzle, Sparkles, CornerUpRight } from 'lucide-react';
import AuthDialog from './AuthDialog';
import '../3dEffects.css';

const MainContent = () => {
    const { t } = useTranslation();
    const [showAuthDialog, setShowAuthDialog] = useState(false);
    const [initialAuthMode, setInitialAuthMode] = useState('choice');
    const videoRef = useRef(null);
    const demoSectionRef = useRef(null);

    // Сохраняем оригинальный стиль безопасно
    useEffect(() => {
        // Получаем текущее значение overflow-x
        const originalOverflow = window.getComputedStyle(document.body).overflowX;

        // Применяем новые стили
        document.body.style.overflowX = 'hidden';

        // Возвращаем оригинальные стили при размонтировании компонента
        return () => {
            document.body.style.overflowX = originalOverflow;
        };
    }, []);

    // Мемоизируем обработчики событий
    const handleAuthClick = useCallback((mode) => {
        setInitialAuthMode(mode);
        setShowAuthDialog(true);
    }, []);

    const scrollToDemo = useCallback(() => {
        demoSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    // Мемоизируем массивы данных
    const services = useMemo(() => [
        {
            icon: <Database className="h-8 w-8 text-orange-500" />,
            title: t('landing.services.sandbox.title'),
            description: t('landing.services.sandbox.description'),
            color: "from-blue-500/10 to-blue-600/10"
        },
        {
            icon: <Brain className="h-8 w-8 text-orange-500" />,
            title: t('landing.services.ai_tutor.title'),
            description: t('landing.services.ai_tutor.description'),
            color: "from-purple-500/10 to-purple-600/10"
        },
        {
            icon: <Puzzle className="h-8 w-8 text-orange-500" />,
            title: t('landing.services.course_builder.title'),
            description: t('landing.services.course_builder.description'),
            color: "from-green-500/10 to-green-600/10"
        },
        {
            icon: <CornerUpRight className="h-8 w-8 text-orange-500" />,
            title: t('landing.services.api.title'),
            description: t('landing.services.api.description'),
            color: "from-amber-500/10 to-amber-600/10"
        }
    ], [t]);

    // Мемоизируем массив функций
    const features = useMemo(() => [
        {
            icon: <Code className="h-8 w-8 text-orange-500" />,
            title: t('landing.features.real_practice.title'),
            description: t('landing.features.real_practice.description')
        },
        {
            icon: <Brain className="h-8 w-8 text-orange-500" />,
            title: t('landing.features.ai_consultant.title'),
            description: t('landing.features.ai_consultant.description')
        },
        {
            icon: <Globe className="h-8 w-8 text-orange-500" />,
            title: t('landing.features.modern_tech.title'),
            description: t('landing.features.modern_tech.description')
        }
    ], [t]);

    // Используем IntersectionObserver для ленивой загрузки видео
    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const source = document.createElement('source');
                    source.src = '/demos/product_demo.mp4';
                    source.type = 'video/mp4';
                    videoElement.appendChild(source);
                    videoElement.load();
                    videoElement.play().catch();
                    observer.unobserve(videoElement);
                }
            });
        }, options);

        observer.observe(videoElement);

        return () => {
            if (videoElement) {
                observer.unobserve(videoElement);
            }
        };
    }, []);

    return (
        <div className="relative min-h-screen w-screen bg-gradient-to-b from-gray-900 to-black text-white">
            {/* Hero Section with Services Platform */}
            <div className="relative min-h-screen">
                <div className="absolute inset-0 w-screen">
                    <div className="absolute inset-0 w-screen bg-[radial-gradient(circle_at_50%_50%,rgba(255,109,0,0.1),transparent_50%)]" />
                </div>

                {/* Hero Content */}
                <div className="relative z-10 container mx-auto px-6 pt-32 md:pt-40 pb-20">
                    <div className="max-w-4xl mx-auto text-center mb-16">
                        <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight">
                            <span className="bg-gradient-to-r from-orange-400 to-orange-500 bg-clip-text text-transparent">
                                {t('landing.hero.title')}
                            </span>
                        </h1>

                        <p className="text-xl md:text-2xl text-gray-300 mb-10 max-w-3xl mx-auto">
                            {t('landing.hero.subtitle')}
                        </p>

                        <div className="flex flex-col sm:flex-row gap-4 justify-center">
                            <button
                                onClick={() => handleAuthClick('register')}
                                className="group relative px-8 py-4 rounded-lg bg-gradient-to-r from-orange-500 to-orange-600 text-white font-semibold text-lg transition-all hover:scale-105"
                                aria-label={t('landing.hero.start_free')}
                            >
                                <span className="relative z-10 flex items-center justify-center gap-2">
                                    {t('landing.hero.start_free')}
                                    <Sparkles className="w-5 h-5" />
                                </span>
                            </button>

                            <button
                                onClick={scrollToDemo}
                                className="px-8 py-4 rounded-lg text-lg font-semibold border border-orange-500 text-orange-500 hover:bg-orange-500/10 transition-colors"
                                aria-label={t('landing.hero.how_it_works')}
                            >
                                {t('landing.hero.how_it_works')}
                            </button>
                        </div>
                    </div>

                    {/* 3D Platform Services */}
                    <div className="mt-16 md:mt-24 max-w-6xl mx-auto">
                        <div className="text-center mb-10">
                            <h2 className="text-3xl md:text-4xl font-bold mb-4">
                                {t('landing.services.title')}
                            </h2>
                            <p className="text-gray-400 max-w-2xl mx-auto">
                                {t('landing.services.subtitle')}
                            </p>
                        </div>

                        <div
                            className="relative transform"
                        >
                            <div
                                className="grid grid-cols-1 md:grid-cols-2 gap-6"
                            >
                                {services.map((service, index) => (
                                    <div
                                        key={index}
                                        className="group relative p-8 rounded-2xl overflow-hidden border border-gray-800 transition-all hover:border-orange-500/50 shadow-xl float-animation"
                                        style={{
                                            transform: `translateZ(${40 * (index + 1)}px)`,
                                            transition: 'transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease',
                                            transformStyle: 'preserve-3d'
                                        }}
                                    >
                                        <div className={`absolute inset-0 bg-gradient-to-br ${service.color} opacity-0 group-hover:opacity-100 transition-opacity`}/>
                                        <div className="platform-shadow"></div>

                                        <div className="relative z-10">
                                            <div className="h-16 w-16 mb-6 rounded-full bg-orange-500/20 flex items-center justify-center transform group-hover:scale-110 transition-transform">
                                                {service.icon}
                                            </div>
                                            <h3 className="text-xl font-bold mb-4 group-hover:text-orange-400 transition-colors">
                                                {service.title}
                                            </h3>
                                            <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                                                {service.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Demo Section */}
            <div ref={demoSectionRef} className="relative py-20">
                <div className="container mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            {t('landing.demo.title')}
                        </h2>
                        <p className="text-gray-400 max-w-2xl mx-auto">
                            {t('landing.demo.subtitle')}
                        </p>
                    </div>

                    <div className="max-w-4xl mx-auto">
                        <div className="relative rounded-xl overflow-hidden shadow-2xl bg-gray-900">
                            <video
                                ref={videoRef}
                                autoPlay={false}
                                loop
                                muted
                                playsInline
                                className="w-full aspect-video object-cover bg-gray-800 flex items-center justify-center"
                                aria-label="Демонстрационное видео продукта"
                            >
                                <div className="text-sm text-gray-300">{t('common.loading') || 'Загрузка...'}</div>
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="relative py-20 w-screen bg-black/40">
                <div className="absolute inset-0 w-screen">
                    <div className="absolute inset-0 w-screen bg-[radial-gradient(circle_at_50%_50%,rgba(255,109,0,0.05),transparent_50%)]" />
                </div>

                <div className="container mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            {t('landing.features.title')}
                        </h2>
                        <p className="text-gray-400 max-w-2xl mx-auto">
                            {t('landing.features.subtitle')}
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                className="group relative p-8 rounded-2xl overflow-hidden border border-gray-800 transition-all hover:border-orange-500/50"
                            >
                                <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 transition-opacity group-hover:opacity-0"/>
                                <div className="absolute inset-0 bg-gradient-to-br from-orange-500/20 to-orange-600/20 opacity-0 group-hover:opacity-100 transition-opacity"/>

                                <div className="relative z-10">
                                    <div className="h-16 w-16 mb-6 rounded-full bg-orange-500/20 flex items-center justify-center transform group-hover:scale-110 transition-transform">
                                        {feature.icon}
                                    </div>
                                    <h3 className="text-xl font-bold mb-4 group-hover:text-orange-400 transition-colors">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Partners Section */}
            <div className="relative py-20">
                <div className="container mx-auto px-6">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">
                            {t('landing.partners.title')}
                        </h2>
                    </div>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-8">
                        <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl flex flex-col items-center transition-all hover:bg-white/15">
                            <div className="h-14 w-48 bg-gray-800 rounded mb-4 flex items-center justify-center">
                                <img
                                    src="/images/microsoft.png"
                                    alt="Microsoft for Startups"
                                    className="max-h-14 max-w-full object-contain"
                                />
                            </div>
                            <span className="text-sm text-gray-300">{t('landing.partners.microsoft')}</span>
                        </div>

                        <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl flex flex-col items-center transition-all hover:bg-white/15">
                            <div className="h-14 w-48 bg-gray-800 rounded mb-4 flex items-center justify-center">
                                <img
                                    src="/images/inzhenerka.png"
                                    alt="Inzhenerka.tech"
                                    className="max-h-14 max-w-full object-contain"
                                />
                            </div>
                            <span className="text-sm text-gray-300">{t('landing.partners.inzhenerka')}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Call to Action Section */}
            <div className="relative py-16 w-screen bg-gradient-to-r from-orange-500/20 to-orange-600/20">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl md:text-4xl font-bold mb-6">
                        {t('landing.cta.title')}
                    </h2>
                    <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
                        {t('landing.cta.subtitle')}
                    </p>
                    <button
                        onClick={() => handleAuthClick('register')}
                        className="px-8 py-4 rounded-lg bg-orange-500 hover:bg-orange-600 text-white font-semibold text-lg transition-all transform hover:scale-105"
                        aria-label={t('landing.cta.button')}
                    >
                        {t('landing.cta.button')}
                    </button>
                </div>
            </div>

            {/* Auth Dialog */}
            <AuthDialog
                open={showAuthDialog}
                onClose={() => setShowAuthDialog(false)}
                initialMode={initialAuthMode}
                onSuccess={() => {
                    setShowAuthDialog(false);
                }}
            />
        </div>
    );
};

export default MainContent;