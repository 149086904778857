import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import {Stage, Layer, Line, Shape} from 'react-konva';
import ComponentRenderer from './canvas/CanvasComponents';
import ContextMenu from './canvas/ContextMenu';
import Grid from './canvas/Grid';
import useCanvasHandlers from './canvas/useCanvasHandlers';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';
import AIAssistant from './AIAssistant';
import i18n from "../../i18n";

// Размеры иконок
const iconWidth = 50;
const iconHeight = 50;

const currentLanguage = i18n.language;

const SystemComponent = ({name, x, y, width, height, onClick}) => {
    return (
        <Shape
            x={x}
            y={y}
            sceneFunc={(context, shape) => {
                context.beginPath();

                // Прямоугольник с белой заливкой
                context.rect(0, 0, width, height);
                context.fillStyle = 'white';
                context.fill();

                // Оранжевая обводка
                context.strokeStyle = '#FF6F00';
                context.lineWidth = 2;
                context.stroke();

                // Текст названия системы
                context.fillStyle = '#333333';
                context.font = 'bold 16px Arial';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(name, width / 2, height / 2);

                context.fillStrokeShape(shape);
            }}
            onMouseEnter={(e) => {
                const stage = e.target.getStage();
                stage.container().style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
                const stage = e.target.getStage();
                stage.container().style.cursor = 'default';
            }}
            onClick={onClick}
            onTap={onClick}
        />
    );
};


// Распределение компонентов по уровням
const distributeComponentsByLevels = (components, canvasWidth, canvasHeight) => {
    const levels = {};

    // Группируем компоненты по уровням
    components.forEach((comp) => {
        if (!levels[comp.type_level]) {
            levels[comp.type_level] = [];
        }
        levels[comp.type_level].push(comp);
    });

    const placedComponents = [];
    const levelKeys = Object.keys(levels).sort((a, b) => parseInt(a) - parseInt(b)); // Сортируем уровни

    // Определяем вертикальный интервал между уровнями
    const levelOffset = canvasHeight ? Math.min(150, canvasHeight / (levelKeys.length + 1)) : 150;

    // Перебираем уровни
    levelKeys.forEach((level, levelIndex) => {
        const levelComponents = levels[level];

        // Определяем горизонтальный интервал между компонентами
        const componentWidth = iconWidth + 60; // Ширина компонента с отступом
        const totalComponentsWidth = levelComponents.length * componentWidth;

        // Проверяем, поместятся ли все компоненты на экране
        let spacing;

        if (totalComponentsWidth > canvasWidth) {
            // Если не помещаются, уменьшаем интервал между ними
            spacing = Math.max(20, (canvasWidth - (levelComponents.length * iconWidth)) / (levelComponents.length + 1));
        } else {
            // Если помещаются, распределяем их равномерно
            spacing = (canvasWidth - totalComponentsWidth) / (levelComponents.length + 1);
        }

        // Расставляем компоненты с учетом вычисленного интервала
        levelComponents.forEach((comp, index) => {
            const x = spacing + index * (iconWidth + spacing);
            const y = (levelIndex + 1) * levelOffset;

            placedComponents.push({
                ...comp,
                x,
                y,
                width: iconWidth,
                height: iconHeight,
            });
        });
    });

    return placedComponents;
};

const SandboxCanvas = ({
                           components,
                           setComponents,
                           setActiveComponent,
                           relations,
                           containerRef,
                           userInfo,
                           currentLayer,
                           sandboxName,
                           onLayerChange,
                           token,
                           sandboxId,
                           activeComponent,
                           activeInfoTab,
                           isAssignmentMode = false,
                           assignmentDetails = null,
                           onAssignmentSubmit = null
                       }) => {
    const stageRef = useRef(null);

    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [contextMenu, setContextMenu] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [lastPointerPosition, setLastPointerPosition] = useState(null);
    const [aiAssistantPrompt, setAiAssistantPrompt] = useState(null);

    // Состояние для всплывающего сообщения
    const [tooltip, setTooltip] = useState({
        visible: false,
        x: 0,
        y: 0,
        description: '',
    });

    useCanvasHandlers({
        containerRef,
        stageRef,
        dimensions,
        setDimensions,
        setScale,
        setPosition,
        setContextMenu,
        components,
        setComponents,
        currentLayer,
        sandboxName,
        onLayerChange,
    });

    // Функция для сохранения позиций компонентов в куки (с дебаунсом)
    const debouncedUpdateComponents = useMemo(() => {
        return debounce((updatedComponents) => {
            // Проверка валидности координат
            const isDataValid = updatedComponents.every(
                (comp) =>
                    typeof comp.x === 'number' &&
                    typeof comp.y === 'number' &&
                    comp.x >= 0 && comp.y >= 0
            );

            if (!isDataValid) {
                console.warn('Невалидные координаты компонентов, пропускаем сохранение');
                return;
            }

            // Подготовка данных для сохранения
            const componentsData = updatedComponents.map((comp) => ({
                id: comp.id,
                x: comp.x,
                y: comp.y,
            }));

            // Сохранение в cookies
            try {
                Cookies.set('sandboxComponents', JSON.stringify(componentsData), { expires: 30 });
            } catch (error) {
                console.error('Ошибка при сохранении компонентов:', error);
            }
        }, 300); // Уменьшили таймаут для более быстрого отклика
    }, []);

    // Функция для загрузки позиций компонентов из куки
    const loadComponentsFromCookies = () => {
        try {
            const savedComponents = Cookies.get('sandboxComponents');
            return savedComponents ? JSON.parse(savedComponents) : null;
        } catch (error) {
            console.error('Ошибка при загрузке компонентов из cookies:', error);
            return null;
        }
    };

    // Используем ResizeObserver для обновления размеров контейнера
    useEffect(() => {
        if (!containerRef.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            if (entries && entries.length > 0) {
                const {width, height} = entries[0].contentRect;
                if (width > 0 && height > 0) {
                    setDimensions({width, height});
                }
            }
        });

        resizeObserver.observe(containerRef.current);

        // Очистка при размонтировании
        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);


    // Первичная загрузка компонентов и их распределение по сетке или из куки
    useEffect(() => {
        if (!isInitialized && dimensions.width > 0 && dimensions.height > 0 && components.length > 0) {
            const savedComponents = loadComponentsFromCookies();

            setComponents((prevComponents) => {
                let updatedComponents;

                if (savedComponents && savedComponents.length === prevComponents.length) {
                    // Проверяем валидность координат сохранённых компонентов
                    const allValidPositions = savedComponents.every(
                        (comp) => typeof comp.x === 'number' &&
                            typeof comp.y === 'number' &&
                            comp.x >= 0 &&
                            comp.y >= 0 &&
                            comp.x < dimensions.width - iconWidth &&
                            comp.y < dimensions.height - iconHeight
                    );

                    if (allValidPositions) {
                        // Обновляем позиции компонентов из сохраненных данных
                        updatedComponents = prevComponents.map((comp) => {
                            const savedComp = savedComponents.find((sc) => sc.id === comp.id);
                            return savedComp
                                ? {...comp, x: savedComp.x, y: savedComp.y}
                                : comp;
                        });
                    } else {
                        // Если позиции невалидны, распределяем заново
                        updatedComponents = distributeComponentsByLevels(
                            prevComponents,
                            dimensions.width,
                            dimensions.height
                        );
                    }
                } else {
                    // Распределяем компоненты по уровням, если нет сохраненных данных или их количество не совпадает
                    updatedComponents = distributeComponentsByLevels(
                        prevComponents,
                        dimensions.width,
                        dimensions.height
                    );
                }

                return updatedComponents;
            });

            setIsInitialized(true);
        }
    }, [dimensions.width, dimensions.height, setComponents, components, isInitialized]);

    // Сохраняем компоненты в cookies при их изменении
    useEffect(() => {
        if (isInitialized && components.length > 0) {
            debouncedUpdateComponents(components);
        }
    }, [components, debouncedUpdateComponents, isInitialized]);

    const handleContextMenu = useCallback((e, comp) => {
        e.evt.preventDefault();
        if (!stageRef.current) return;
        const pointer = stageRef.current.getPointerPosition();
        if (!pointer) return;
        setContextMenu({
            x: pointer.x,
            y: pointer.y,
            componentId: comp.id,
            componentName: comp.name
        });
    }, []);

    const handleStageMouseDown = useCallback((e) => {
        if (e.target === e.target.getStage()) {
            setIsDragging(true);
            setLastPointerPosition(e.target.getStage().getPointerPosition());
        }
    }, []);

    const handleStageMouseMove = useCallback((e) => {
        if (!isDragging) return;

        const stage = e.target.getStage();
        const pointerPosition = stage.getPointerPosition();
        if (!pointerPosition || !lastPointerPosition) return;

        const dragDiffX = pointerPosition.x - lastPointerPosition.x;
        const dragDiffY = pointerPosition.y - lastPointerPosition.y;

        if (Math.abs(dragDiffX) < 1 && Math.abs(dragDiffY) < 1) return; // Игнорируем микродвижения

        setPosition(prevPosition => ({
            x: prevPosition.x + dragDiffX,
            y: prevPosition.y + dragDiffY,
        }));

        setLastPointerPosition(pointerPosition);
    }, [isDragging, lastPointerPosition]);

    const handleStageMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);


    const handleComponentClick = useCallback(
        (comp) => {
            // Убираем проверку на assignmentMode
            setActiveComponent((prevActiveComponent) => {
                if (prevActiveComponent && prevActiveComponent.id === comp.id) {
                    return null;
                }
                return comp;
            });
        },
        [setActiveComponent]
    );

    const [dashOffset, setDashOffset] = useState(0);
    useEffect(() => {
        const anim = setInterval(() => {
            setDashOffset((prevOffset) => (prevOffset + 1) % 10);
        }, 100);
        return () => clearInterval(anim);
    }, []);

    // Функции для работы с всплывающим сообщением
    const handleMouseEnter = useCallback((e, relation) => {
        if (!stageRef.current) return;
        const pointerPosition = stageRef.current.getPointerPosition();
        if (!pointerPosition) return;

        setTooltip({
            visible: true,
            x: pointerPosition.x + 10,
            y: pointerPosition.y + 10,
            description: relation.description || 'Нет описания',
        });
    }, []);

    const handleMouseLeave = useCallback(() => {
        setTooltip({visible: false, x: 0, y: 0, description: ''});
    }, []);

    const handleSystemClick = useCallback(() => {
        onLayerChange('Подробная архитектура');
    }, [onLayerChange]);

    const preventComponentOverlap = (components, draggedId, newX, newY, dimensions) => {
        // Размеры компонента с отступами
        const componentWidth = iconWidth + 10;
        const componentHeight = iconHeight + 20;

        // Находим все компоненты, кроме текущего
        const otherComponents = components.filter(comp => comp.id !== draggedId);

        // Проверяем наличие перекрытий
        for (const comp of otherComponents) {
            // Проверяем перекрытие по X и Y
            if (
                newX < comp.x + componentWidth &&
                newX + componentWidth > comp.x &&
                newY < comp.y + componentHeight &&
                newY + componentHeight > comp.y
            ) {
                // Есть перекрытие, смещаем новую позицию
                // Смещение на минимальное значение, чтобы избежать перекрытия
                const xOverlap = Math.min(
                    newX + componentWidth - comp.x,
                    comp.x + componentWidth - newX
                );
                const yOverlap = Math.min(
                    newY + componentHeight - comp.y,
                    comp.y + componentHeight - newY
                );

                // Смещаем по меньшему перекрытию
                if (xOverlap < yOverlap) {
                    // Смещаем по X
                    newX = newX < comp.x ? newX - xOverlap : newX + xOverlap;
                } else {
                    // Смещаем по Y
                    newY = newY < comp.y ? newY - yOverlap : newY + yOverlap;
                }
            }
        }

        // Ограничиваем позицию границами холста
        newX = Math.max(0, Math.min(newX, dimensions.width - componentWidth));
        newY = Math.max(0, Math.min(newY, dimensions.height - componentHeight));

        return { x: newX, y: newY };
    };

    const handleDragMove = useCallback((e, id) => {
        const { x, y } = e.target.position();
        const gridSize = 10; // Размер сетки для выравнивания

        // Выравниваем по сетке
        let newX = Math.round(x / gridSize) * gridSize;
        let newY = Math.round(y / gridSize) * gridSize;

        // Предотвращаем наложение компонентов
        setComponents((prevComponents) => {
            const { x: adjustedX, y: adjustedY } = preventComponentOverlap(
                prevComponents, id, newX, newY, dimensions
            );

            return prevComponents.map((comp) =>
                comp.id === id ? { ...comp, x: adjustedX, y: adjustedY } : comp
            );
        });
    }, [dimensions, setComponents]);

    const MemoizedComponentRenderer = React.memo(ComponentRenderer);

    const memoizedComponents = useMemo(() => {
        if (currentLayer === 'Верхнеуровневая архитектура') {
            const environmentComponents = components.filter(comp => comp.category_name === 'environment');

            // Вычисляем размеры и позицию для системного компонента
            const systemWidth = Math.min(200, dimensions.width * 0.2);
            const systemHeight = Math.min(100, dimensions.height * 0.1);
            const systemX = (dimensions.width - systemWidth) / 2;
            const systemY = (dimensions.height - systemHeight) / 2;

            const systemComponent = {
                id: 'system',
                name: sandboxName,
                category_name: 'system',
                type_name: 'System',
                x: systemX,
                y: systemY,
                width: systemWidth,
                height: systemHeight,
                description: 'Сгенерированная система. Кликните для просмотра подробной архитектуры'
            };

            // Распределяем компоненты окружения вокруг системного компонента
            const angle = (2 * Math.PI) / environmentComponents.length;
            const radius = Math.min(dimensions.width, dimensions.height) * 0.3;

            environmentComponents.forEach((comp, index) => {
                comp.x = systemX + systemWidth / 2 + radius * Math.cos(angle * index) - iconWidth / 2;
                comp.y = systemY + systemHeight / 2 + radius * Math.sin(angle * index) - iconHeight / 2;
            });

            return [
                ...environmentComponents.map(comp => (
                    <MemoizedComponentRenderer
                        key={comp.id}
                        comp={comp}
                        onContextMenu={handleContextMenu}
                        onClick={handleComponentClick}
                        onDragMove={handleDragMove}
                    />
                )),
                <SystemComponent
                    key="system"
                    {...systemComponent}
                    onClick={handleSystemClick}
                />,
            ];
        } else {
            return components.map((comp) => (
                <MemoizedComponentRenderer
                    key={comp.id}
                    comp={comp}
                    onContextMenu={handleContextMenu}
                    onClick={handleComponentClick}
                    onDragMove={handleDragMove}
                />
            ));
        }
    }, [components, currentLayer, sandboxName, dimensions, handleContextMenu, handleComponentClick, handleDragMove, handleSystemClick]);

    const memoizedComponentPositions = useMemo(() => {
        return components.reduce((acc, comp) => {
            if (typeof comp.x === 'number' && typeof comp.y === 'number') {
                acc[comp.id] = {
                    x: comp.x,
                    y: comp.y,
                    width: iconWidth,
                    height: iconHeight,
                };
            }
            return acc;
        }, {});
    }, [components]);

    // Функция для получения позиции компонента по id и расчета его центра
    const getComponentPosition = useCallback(
        (id) => {
            const component = components.find((comp) => comp.id === id);
            return component || memoizedComponentPositions[id] || null;
        },
        [components, memoizedComponentPositions]
    );

    // Кэшируем результаты getComponentPosition для всех компонентов
    const positionCache = useMemo(() => {
        const cache = {};
        components.forEach(comp => {
            const pos = getComponentPosition(comp.id);
            if (pos) {
                cache[comp.id] = pos;
            }
        });
        return cache;
    }, [components, getComponentPosition]);

    const memoizedRelations = useMemo(() => {
        if (!relations.length && currentLayer !== 'Верхнеуровневая архитектура') return [];

        if (currentLayer === 'Верхнеуровневая архитектура') {
            // Кэшируем центральные координаты для верхнеуровневой архитектуры
            const centerX = dimensions.width / 2;
            const centerY = dimensions.height / 2;

            return components
                .filter(comp => comp.category_name === 'environment')
                .map((comp) => {
                    const compPos = getComponentPosition(comp.id);
                    if (!compPos) return null;

                    const fromXCenter = compPos.x + (compPos.width || iconWidth) / 2;
                    const fromYCenter = compPos.y + (compPos.height || iconHeight) / 2;

                    const relationKey = `rel_${comp.id}_system`;

                    return (
                        <React.Fragment key={relationKey}>
                            <Line
                                points={[fromXCenter, fromYCenter, centerX, centerY]}
                                stroke="transparent"
                                strokeWidth={6}
                                onMouseEnter={(e) => handleMouseEnter(e, { description: `Связь с ${comp.name}` })}
                                onMouseLeave={handleMouseLeave}
                            />
                            <Line
                                points={[fromXCenter, fromYCenter, centerX, centerY]}
                                stroke="gray"
                                strokeWidth={1}
                                dash={[5, 5]}
                                dashOffset={dashOffset}
                            />
                        </React.Fragment>
                    );
                })
                .filter(Boolean);
        } else {
            return relations
                .map((relation) => {
                    const fromPos = positionCache[relation.from_component];
                    const toPos = positionCache[relation.to_component];

                    if (!fromPos || !toPos) return null;

                    const fromXCenter = fromPos.x + (fromPos.width || iconWidth) / 2;
                    const fromYCenter = fromPos.y + (fromPos.height || iconHeight) / 2;
                    const toXCenter = toPos.x + (toPos.width || iconWidth) / 2;
                    const toYCenter = toPos.y + (toPos.height || iconHeight) / 2;

                    const relationKey = `rel_${relation.id || `${relation.from_component}_${relation.to_component}`}`;

                    return (
                        <React.Fragment key={relationKey}>
                            <Line
                                points={[fromXCenter, fromYCenter, toXCenter, toYCenter]}
                                stroke="transparent"
                                strokeWidth={6}
                                onMouseEnter={(e) => handleMouseEnter(e, relation)}
                                onMouseLeave={handleMouseLeave}
                            />
                            <Line
                                points={[fromXCenter, fromYCenter, toXCenter, toYCenter]}
                                stroke="gray"
                                strokeWidth={1}
                                dash={[5, 5]}
                                dashOffset={dashOffset}
                            />
                        </React.Fragment>
                    );
                })
                .filter(Boolean);
        }
    }, [
        currentLayer,
        components,
        relations,
        dimensions.width,
        dimensions.height,
        dashOffset,
        handleMouseEnter,
        handleMouseLeave,
        getComponentPosition,
        positionCache
    ]);


    // Проверка размеров после вызова хуков
    if (dimensions.width === 0 || dimensions.height === 0) {
        return null;
    }

    return (
        <div
            onContextMenu={(e) => e.preventDefault()}
        >
            <Stage
                width={dimensions.width}
                height={dimensions.height}
                scaleX={scale}
                scaleY={scale}
                x={position.x}
                y={position.y}
                ref={stageRef}
                onMouseDown={handleStageMouseDown}
                onMouseMove={handleStageMouseMove}
                onMouseUp={handleStageMouseUp}
                onMouseLeave={handleStageMouseUp}
            >
                <Grid width={dimensions.width} height={dimensions.height}/>
                <Layer>{memoizedRelations}</Layer>
                <Layer>{memoizedComponents}</Layer>
            </Stage>

            {/* Всплывающее сообщение */}
            {tooltip.visible && (
                <div
                    style={{
                        position: 'absolute',
                        top: tooltip.y,
                        left: tooltip.x,
                        background: 'rgba(211, 211, 211, 0.5)',
                        padding: '5px',
                        borderRadius: '4px',
                        pointerEvents: 'none',
                        fontSize: '12px',
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {tooltip.description}
                </div>
            )}

            {contextMenu && (
                <ContextMenu
                    position={contextMenu}
                    componentName={contextMenu.componentName}
                    onAction={(action, prompt) => {
                        if (action === 'describe') {
                            setAiAssistantPrompt(prompt);
                        }
                        setContextMenu(null);
                    }}
                />
            )}

            <AIAssistant
                token={token}
                sandboxId={sandboxId}
                userId={userInfo.id}
                language={currentLanguage}
                selectedComponent={activeComponent ? activeComponent.name : null}
                selectedTab={activeInfoTab}
                initialPrompt={aiAssistantPrompt}
            />
        </div>
    );
};

export default SandboxCanvas;