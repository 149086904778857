import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useImage from 'use-image';
import InfoTab from './tabs/InfoTab';
import RestTab from './tabs/RestTab';
import GraphQLTab from './tabs/GraphQLTab';
import SQLTab from './tabs/SQLTab';
import AssignmentTab from './tabs/AssignmentTab';

const TOOL_PANEL_WIDTH = 48;

// Основной компонент панели с инструментами и вкладками
const InfoPanel = ({activeComponent, onTabChange, token, assignment, assignmentStatus }) => {
  const { t } = useTranslation();
  const [activeTool, setActiveTool] = useState(null);
  const [panelWidth, setPanelWidth] = useState(TOOL_PANEL_WIDTH);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [tools, setTools] = useState([]);

  // Функция для получения инструментов
  const getTools = useCallback((hasAssignment) => [
    { name: t('info_panel.tools.component_info'), icon: '/icons/info.png', width: 400 },
    { name: t('info_panel.tools.rest_request'), icon: '/icons/rest.png', width: 900 },
    { name: t('info_panel.tools.graphql_request'), icon: '/icons/graphql.png', width: 900 },
    { name: t('info_panel.tools.sql_request'), icon: '/icons/sql.png', width: 900 },
    ...(hasAssignment ? [{ name: t('info_panel.tools.assignment'), icon: '/icons/assignment.png', width: 400 }] : [])
  ], [t]);

  // Загружаем иконки
  const [infoIcon] = useImage('/icons/info.png');
  const [restIcon] = useImage('/icons/rest.png');
  const [graphqlIcon] = useImage('/icons/graphql.png');
  const [sqlIcon] = useImage('/icons/sql.png');
  const [assignmentIcon] = useImage('/icons/assignment.png');

  // Массив с загруженными иконками
  const icons = {
    [t('info_panel.tools.component_info')]: infoIcon,
    [t('info_panel.tools.rest_request')]: restIcon,
    [t('info_panel.tools.graphql_request')]: graphqlIcon,
    [t('info_panel.tools.sql_request')]: sqlIcon,
    [t('info_panel.tools.assignment')]: assignmentIcon
  };

  // Обновляем список инструментов при изменении assignment
  useEffect(() => {
    setTools(getTools(Boolean(assignment)));
    if (assignment) {
      onTabChange(t('info_panel.tools.assignment'));
    }
  }, [assignment, onTabChange, getTools, t]);

  useEffect(() => {
    if (activeComponent) {
      setActiveTool(t('info_panel.tools.component_info'));
      setPanelWidth(TOOL_PANEL_WIDTH + 400);
      setIsCollapsed(false);
    }
  }, [activeComponent, t]);

  const handleToolChange = useCallback((tool) => {
    if (isCollapsed || tool.name !== activeTool) {
      setActiveTool(tool.name);
      onTabChange(tool.name);
      setPanelWidth(TOOL_PANEL_WIDTH + tool.width);
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
      setPanelWidth(TOOL_PANEL_WIDTH);
    }
  }, [isCollapsed, activeTool, onTabChange]);

  // Компонент для панели инструментов
  const ToolPanel = ({ activeTool, handleToolChange, icons, tools }) => (
      <div
          className="flex flex-col bg-transparent overflow-y-auto justify-start"
          style={{width: TOOL_PANEL_WIDTH}}
      >
        {tools.map((tool) => (
            <div key={tool.name} className={'mt-6 mr-4'}>
              <button
                  onClick={() => handleToolChange(tool)}
                  className={`
                  flex items-center justify-center w-full h-12 rounded
                  ${activeTool === tool.name ? 'bg-gray-100' : 'hover:bg-gray-50'}
                  transition-colors duration-200 ease-in-out
                `}
                  title={tool.name}
              >
                {icons[tool.name] ? (
                    <img
                        src={icons[tool.name].src}
                        alt={tool.name}
                        className="w-6 h-6"
                    />
                ) : (
                    '...'
                )}
              </button>
            </div>
        ))}
      </div>
  );

  // Компонент для панели с вкладками
  const TabPanel = ({ activeTool, panelWidth, isCollapsed, activeComponent, token, assignment, assignmentStatus }) => {
    if (!activeTool) return null;

    const renderContent = () => {
      switch (activeTool) {
        case t('info_panel.tools.component_info'):
          return <InfoTab activeComponent={activeComponent}/>;
        case t('info_panel.tools.rest_request'):
          return <RestTab activeComponent={activeComponent}/>;
        case t('info_panel.tools.graphql_request'):
          return <GraphQLTab activeComponent={activeComponent}/>;
        case t('info_panel.tools.sql_request'):
          return <SQLTab activeComponent={activeComponent} token={token}/>;
        case t('info_panel.tools.assignment'):
          return assignment ? (
              <AssignmentTab
                  assignment={assignment}
                  assignmentStatus={assignmentStatus}
                  token={token}
              />
          ) : null;
        default:
          return null;
      }
    };

    return (
        <div
            className={`bg-transparent absolute top-0 h-full overflow-hidden ${
                isCollapsed ? '' : ''
            }`}
            style={{
              right: `${TOOL_PANEL_WIDTH}px`,
              width: isCollapsed ? '0' : `${panelWidth - TOOL_PANEL_WIDTH}px`,
              transition: 'width 0.3s ease',
            }}
        >
          <div
              className={`h-full overflow-auto ${isCollapsed ? '' : 'p-4'}`}
              style={{ display: isCollapsed ? 'none' : 'block' }}
          >
            {renderContent()}
          </div>
        </div>
    );
  };

  return (
      <div
          className="relative h-full overflow-visible"
          style={{ width: `${TOOL_PANEL_WIDTH}px` }}
      >
        <ToolPanel
            activeTool={activeTool}
            handleToolChange={handleToolChange}
            icons={icons}
            tools={tools}
        />
        <TabPanel
            activeTool={activeTool}
            panelWidth={panelWidth}
            isCollapsed={isCollapsed}
            activeComponent={activeComponent}
            token={token}
            assignment={assignment}
            assignmentStatus={assignmentStatus}
        />
      </div>
  );
};

export default InfoPanel;