// src/pages/TermsOfService.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto px-6 py-12">
            <h1 className="text-3xl font-bold text-center mb-8 text-gray-900">{t('terms_of_service.title')}</h1>

            <p className="text-base text-gray-500 mb-4">
                <strong>{t('terms_of_service.last_updated')}:</strong> {t('terms_of_service.date')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. {t('terms_of_service.introduction.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.introduction.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. {t('terms_of_service.eligibility.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.eligibility.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. {t('terms_of_service.subscriptions.title')}</h2>
            <ul className="list-disc pl-6 mb-6">
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.subscriptions.plans_title')}:</strong> {t('terms_of_service.subscriptions.plans_content')}
                </li>
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.subscriptions.billing_title')}:</strong> {t('terms_of_service.subscriptions.billing_content')}
                </li>
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.subscriptions.refunds_title')}:</strong> {t('terms_of_service.subscriptions.refunds_content')}
                </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. {t('terms_of_service.license.title')}</h2>
            <ul className="list-disc pl-6 mb-6">
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.license.grant_title')}:</strong> {t('terms_of_service.license.grant_content')}
                </li>
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.license.prohibited_title')}:</strong> {t('terms_of_service.license.prohibited_content')}
                </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. {t('terms_of_service.sandbox.title')}</h2>
            <ul className="list-disc pl-6 mb-6">
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.sandbox.data_preservation_title')}:</strong> {t('terms_of_service.sandbox.data_preservation_content')}
                </li>
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.sandbox.user_content_title')}:</strong> {t('terms_of_service.sandbox.user_content_content')}
                </li>
                <li className="text-base text-gray-500 mb-2">
                    <strong>{t('terms_of_service.sandbox.interruptions_title')}:</strong> {t('terms_of_service.sandbox.interruptions_content')}
                </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. {t('terms_of_service.intellectual_property.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.intellectual_property.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. {t('terms_of_service.termination.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.termination.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. {t('terms_of_service.disclaimer.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.disclaimer.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. {t('terms_of_service.liability.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.liability.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. {t('terms_of_service.governing_law.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.governing_law.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. {t('terms_of_service.changes.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.changes.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">12. {t('terms_of_service.contact.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('terms_of_service.contact.content')}
            </p>
            <p className="text-base text-gray-500 mb-6">
                <strong>{t('terms_of_service.contact.email_prefix')}</strong> info@dvakota.com<br />
            </p>
        </div>
    );
};

export default TermsOfService;