import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from '../../App';
import axios from 'axios';
import Markdown from 'react-markdown';
import { Copy, Send, Plus, Edit2, MessageCircle, Loader, Trash2, ChevronLeft } from 'lucide-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const DevAssistant = () => {
    const { token } = useContext(AuthContext);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [editingConversation, setEditingConversation] = useState(null);
    const [newConversationName, setNewConversationName] = useState('');
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);

    // Fetch conversations
    useEffect(() => {
        fetchConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    // Fetch conversation history when conversation changes
    useEffect(() => {
        if (currentConversation) {
            fetchConversationHistory(currentConversation.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConversation]);

    // Scroll to bottom of messages when messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Auto resize textarea
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
        }
    }, [inputMessage]);

    // Prevent page scroll when messages container scrolls to bottom
    useEffect(() => {
        const messagesContainer = document.getElementById('messages-container');
        if (messagesContainer) {
            const handleScroll = (e) => {
                e.stopPropagation();
            };
            messagesContainer.addEventListener('scroll', handleScroll);
            return () => {
                messagesContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const fetchConversations = async () => {
        try {
            const response = await axios.get('/api/aitutor/conversations/', {
                headers: { Authorization: `Token ${token}` },
            });
            // Проверяем, что ответ является массивом, иначе устанавливаем пустой массив
            const conversationsData = Array.isArray(response.data) ? response.data : [];
            setConversations(conversationsData);

            // Select first conversation or create new one if none exist
            if (conversationsData.length > 0 && !currentConversation) {
                setCurrentConversation(conversationsData[0]);
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
            setConversations([]);
        }
    };

    const fetchConversationHistory = async (conversationId) => {
        try {
            const response = await axios.get(`/api/aitutor/conversations/${conversationId}/chat-history/`, {
                headers: { Authorization: `Token ${token}` },
            });
            // Проверяем, что ответ является массивом, иначе устанавливаем пустой массив
            setMessages(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching conversation history:', error);
            setMessages([]);
        }
    };

    const createNewConversation = async () => {
        try {
            const response = await axios.post(
                '/api/aitutor/conversations/',
                { name: 'Новая беседа' },
                { headers: { Authorization: `Token ${token}` } }
            );
            setConversations([...conversations, response.data]);
            setCurrentConversation(response.data);
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    };

    const updateConversationName = async (id, name) => {
        try {
            await axios.patch(
                `/api/aitutor/conversations/${id}/`,
                { name },
                { headers: { Authorization: `Token ${token}` } }
            );

            setConversations(
                conversations.map(conv =>
                    conv.id === id ? { ...conv, name } : conv
                )
            );

            if (currentConversation?.id === id) {
                setCurrentConversation({ ...currentConversation, name });
            }

            setEditingConversation(null);
        } catch (error) {
            console.error('Error updating conversation name:', error);
        }
    };

    const archiveConversation = async (id) => {
        try {
            await axios.patch(
                `/api/aitutor/conversations/${id}/`,
                { archive: true },
                { headers: { Authorization: `Token ${token}` } }
            );

            const updatedConversations = conversations.filter(conv => conv.id !== id);
            setConversations(updatedConversations);

            if (currentConversation?.id === id) {
                setCurrentConversation(updatedConversations.length > 0 ? updatedConversations[0] : null);
            }
        } catch (error) {
            console.error('Error archiving conversation:', error);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();

        if (!inputMessage.trim() || !currentConversation) return;

        // Optimistically add message to UI
        const userMessage = {
            id: Date.now(), // Temporary ID
            role: 'user',
            content: inputMessage,
            timestamp: new Date().toISOString(),
        };

        setMessages([...messages, userMessage]);
        setInputMessage('');
        setLoading(true);

        try {
            const response = await axios.post(
                '/api/devtools/chat/',
                {
                    question: inputMessage,
                    conversation_id: currentConversation.id
                },
                { headers: { Authorization: `Token ${token}` } }
            );

            // Add assistant's response
            const assistantMessage = {
                id: Date.now() + 1, // Temporary ID
                role: 'assistant',
                content: response.data.response,
                timestamp: new Date().toISOString(),
            };

            setMessages(prev => [...prev, assistantMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const startEditingConversation = (conversation) => {
        setEditingConversation(conversation.id);
        setNewConversationName(conversation.name);
    };

    const handleEditFormSubmit = (e, id) => {
        e.preventDefault();
        if (newConversationName.trim()) {
            updateConversationName(id, newConversationName.trim());
        }
    };

    return (
        <div className="flex h-full overflow-hidden">
            {/* Conversations Sidebar */}
            {showSidebar && (
                <div className="w-64 sm:w-80 border-r border-gray-700 flex flex-col min-h-full">
                    <div className="p-4 border-b border-gray-800 flex justify-between items-center">
                        <h2 className="font-semibold text-white text-sm sm:text-base">Беседы</h2>
                        <button
                            onClick={createNewConversation}
                            className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-full"
                            title="Новая беседа"
                        >
                            <Plus size={18} />
                        </button>
                    </div>

                    <div className="flex-1 overflow-y-auto">
                        {!Array.isArray(conversations) || conversations.length === 0 ? (
                            <div className="p-4 text-center text-gray-400">
                                Нет бесед. Создайте новую беседу.
                            </div>
                        ) : (
                            <ul>
                                {Array.isArray(conversations) && conversations.map((conversation) => (
                                    <li key={conversation.id} className="border-b border-gray-800 last:border-b-0">
                                        {editingConversation === conversation.id ? (
                                            <form onSubmit={(e) => handleEditFormSubmit(e, conversation.id)} className="p-2 flex">
                                                <input
                                                    type="text"
                                                    value={newConversationName}
                                                    onChange={(e) => setNewConversationName(e.target.value)}
                                                    className="flex-1 border border-gray-700 rounded px-2 py-1 text-sm bg-gray-800 text-white"
                                                    autoFocus
                                                />
                                                <button type="submit" className="ml-2 p-1 bg-orange-500 text-white rounded text-sm">
                                                    OK
                                                </button>
                                            </form>
                                        ) : (
                                            <div
                                                className={`p-2 sm:p-3 flex items-center justify-between cursor-pointer ${
                                                    currentConversation?.id === conversation.id
                                                        ? 'bg-gray-700 border-l-4 border-orange-500'
                                                        : 'hover:bg-gray-700'
                                                }`}
                                                onClick={() => setCurrentConversation(conversation)}
                                            >
                                                <div className="flex items-center space-x-2 sm:space-x-3">
                                                    <MessageCircle size={16} className="text-gray-400" />
                                                    <span className="text-white truncate text-sm sm:text-base">
                                                        {conversation.name}
                                                    </span>
                                                </div>

                                                <div className="flex space-x-1">
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            startEditingConversation(conversation);
                                                        }}
                                                        className="p-1 text-gray-400 hover:text-white rounded"
                                                        title="Переименовать"
                                                    >
                                                        <Edit2 size={12} className="sm:w-3.5 sm:h-3.5" />
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            archiveConversation(conversation.id);
                                                        }}
                                                        className="p-1 text-gray-400 hover:text-orange-400 rounded"
                                                        title="Удалить"
                                                    >
                                                        <Trash2 size={12} className="sm:w-3.5 sm:h-3.5" />
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            )}

            {/* Main Chat Area */}
            <div className="flex-1 flex flex-col min-h-full">
                {/* Chat Header */}
                <div className="p-3 sm:p-4 border-b border-gray-800 flex items-center">
                    <button
                        className="p-1 mr-2 sm:mr-3 text-gray-400 hover:text-white hover:bg-gray-700 rounded-full md:hidden"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        {showSidebar ? <ChevronLeft size={18} /> : <MessageCircle size={18} />}
                    </button>

                    <h3 className="font-semibold text-white flex-1 truncate text-sm sm:text-base">
                        {currentConversation?.name || 'Выберите беседу'}
                    </h3>

                    {!showSidebar && (
                        <button
                            onClick={createNewConversation}
                            className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-full"
                            title="Новая беседа"
                        >
                            <Plus size={18} />
                        </button>
                    )}
                </div>

                {/* Message Area */}
                <div className="flex-1 overflow-y-auto p-4 bg-gray-900" id="messages-container">
                    {!currentConversation ? (
                        <div className="h-full flex items-center justify-center">
                            <div className="text-center p-6 max-w-md">
                                <MessageCircle size={48} className="mx-auto mb-4 text-gray-600" />
                                <h3 className="text-xl font-bold text-white mb-2">
                                    Выберите беседу
                                </h3>
                                <button
                                    onClick={createNewConversation}
                                    className="px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-md transition-colors"
                                >
                                    Новая беседа
                                </button>
                            </div>
                        </div>
                    ) : !Array.isArray(messages) || messages.length === 0 ? (
                        <div className="h-full flex items-center justify-center">
                            <div className="text-center p-6 max-w-md">
                                <MessageCircle size={48} className="mx-auto mb-4 text-gray-600" />
                                <h3 className="text-xl font-bold text-white mb-2">
                                    Начните новую беседу
                                </h3>
                                <p className="text-gray-400 mb-4">
                                    Задайте вопрос ассистенту по разработке
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-6">
                            {Array.isArray(messages) && messages.map((msg) => (
                                <div
                                    key={msg.id}
                                    className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`max-w-[85%] sm:max-w-[80%] rounded-lg p-3 sm:p-4 ${
                                            msg.role === 'user'
                                                ? 'bg-orange-500 text-white'
                                                : 'bg-gray-800 border border-gray-700 text-white'
                                        }`}
                                    >
                                        {msg.role === 'user' ? (
                                            <div>{msg.content}</div>
                                        ) : (
                                            <Markdown
                                                    components={{
                                                        code({ node, inline, className, children, ...props }) {
                                                            const match = /language-(\w+)/.exec(className || '');
                                                            return !inline && match ? (
                                                            <div className="relative mt-3 mb-3">
                                                                <div className="absolute top-0 right-0 flex">
                                                                    <div className="bg-slate-700 text-slate-300 text-xs px-2 py-1 rounded-tr">
                                                                        {match[1]}
                                                                    </div>
                                                                    <button
                                                                        className="p-1 bg-slate-700 text-slate-300 hover:text-white rounded-br"
                                                                        onClick={() => navigator.clipboard.writeText(String(children))}
                                                                        title="Копировать"
                                                                    >
                                                                        <Copy size={14} />
                                                                    </button>
                                                                </div>
                                                                <SyntaxHighlighter
                                                                    style={atomOneDark}
                                                                    language={match[1]}
                                                                    customStyle={{
                                                                        marginTop: '0',
                                                                        paddingTop: '2rem'
                                                                    }}
                                                                    {...props}
                                                                >
                                                                    {String(children)}
                                                                </SyntaxHighlighter>
                                                            </div>
                                                        ) : (
                                                            <code
                                                                className={`${className} bg-slate-600 dark:bg-slate-700 px-1 py-0.5 rounded text-sm`}
                                                                {...props}
                                                            >
                                                                {children}
                                                            </code>
                                                        );
                                                    },
                                                    p: ({ children }) => <p className="mb-4 last:mb-0">{children}</p>,
                                                    ul: ({ children }) => <ul className="list-disc pl-6 mb-4 last:mb-0">{children}</ul>,
                                                    ol: ({ children }) => <ol className="list-decimal pl-6 mb-4 last:mb-0">{children}</ol>,
                                                    li: ({ children }) => <li className="mb-1">{children}</li>,
                                                    h1: ({ children }) => <h1 className="text-xl font-bold mb-4">{children}</h1>,
                                                    h2: ({ children }) => <h2 className="text-lg font-bold mb-3">{children}</h2>,
                                                    h3: ({ children }) => <h3 className="text-md font-bold mb-2">{children}</h3>,
                                                }}
                                            >
                                                {msg.content}
                                            </Markdown>
                                        )}
                                        <div
                                            className={`text-xs mt-2 ${
                                                msg.role === 'user'
                                                    ? 'text-orange-200'
                                                    : 'text-gray-400'
                                            }`}
                                        >
                                            {new Date(msg.timestamp).toLocaleTimeString()}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef}></div>
                        </div>
                    )}
                </div>

                {currentConversation && (
                    <div className="p-3 sm:p-4 border-t border-gray-800">
                        <form onSubmit={handleSendMessage} className="flex">
                            <div className="flex-1 relative">
                                <textarea
                                    ref={textareaRef}
                                    value={inputMessage}
                                    onChange={(e) => setInputMessage(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Задайте вопрос..."
                                    className="w-full border border-gray-700 rounded-lg pl-3 pr-10 py-2 sm:pl-4 sm:pr-12 sm:py-3 resize-none bg-gray-800 text-white text-sm sm:text-base"
                                    rows={1}
                                    disabled={loading}
                                />
                                <button
                                    type="submit"
                                    disabled={loading || !inputMessage.trim()}
                                    className="absolute right-2 sm:right-3 bottom-2 sm:bottom-3 text-orange-500 hover:text-orange-300 disabled:text-gray-600 disabled:cursor-not-allowed"
                                >
                                    {loading ? <Loader size={18} className="animate-spin" /> : <Send size={18} />}
                                </button>
                            </div>
                        </form>
                        {loading && (
                            <div className="mt-2 text-xs sm:text-sm text-gray-400 animate-pulse">
                                Ассистент печатает...
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DevAssistant;