// src/pages/PrivacyPolicy.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto px-6 py-12">
            <h1 className="text-3xl font-bold text-center mb-8 text-gray-400">{t('privacy_policy.title')}</h1>

            <p className="text-lg text-gray-900 mb-6">
                {t('privacy_policy.intro')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacy_policy.collected_info.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('privacy_policy.collected_info.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacy_policy.data_usage.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('privacy_policy.data_usage.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('privacy_policy.data_transfer.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('privacy_policy.data_transfer.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-400 mb-4">{t('privacy_policy.data_security.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('privacy_policy.data_security.content')}
            </p>

            <h2 className="text-2xl font-semibold text-gray-400 mb-4">{t('privacy_policy.user_rights.title')}</h2>
            <p className="text-base text-gray-500 mb-6">
                {t('privacy_policy.user_rights.content')}
            </p>

            <p className="text-base text-center text-gray-800">
                {t('privacy_policy.contact_note')}
            </p>
        </div>
    );
};

export default PrivacyPolicy;