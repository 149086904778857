// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';
import sr from './locales/sr/translation.json';
import hr from './locales/hr/translation.json';
import es from './locales/es/translation.json';
import pt from './locales/pt/translation.json';
import sl from './locales/sl/translation.json';

// Инициализация i18next
i18n
    .use(LanguageDetector) // Использование плагина для определения языка
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ru: { translation: ru },
            sr: { translation: sr },
            hr: { translation: hr },
            es: { translation: es },
            pt: { translation: pt },
            sl: { translation: sl },
        },
        fallbackLng: 'en', // Язык по умолчанию, если нужный язык отсутствует
        interpolation: {
            escapeValue: false, // React уже предотвращает XSS
        },
        supportedLngs: ['en', 'ru', 'sr', 'hr', 'es', 'pt', 'sl'], // Добавлен список поддерживаемых языков
        detection: {
            // Настройки определения языка
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'], // Сохраняет язык в localStorage или cookie
            lookupQuerystring: 'lng', // Параметр в URL для определения языка
            lookupCookie: 'i18next', // Имя cookie
            lookupLocalStorage: 'i18nextLng', // Ключ в localStorage
        },
    });

export default i18n;