import React, { useState } from 'react';
import DevAssistant from '../components/devtools/DevAssistant';
import Localization from '../components/devtools/Localization';

const DevTools = () => {
    const [activeTab, setActiveTab] = useState('assistant');

    return (
        <div className="fixed top-[75px] left-0 right-0 bottom-0 bg-gray-900 text-white overflow-hidden">
            <div className="w-full h-full flex flex-col">
                <nav className="flex flex-wrap space-x-1 border-b border-gray-800 px-2 sm:px-4 overflow-x-auto">
                    <button
                        onClick={() => setActiveTab('dashboard')}
                        className={`py-2 sm:py-3 px-3 sm:px-4 rounded-t-lg font-medium text-xs sm:text-sm transition-colors whitespace-nowrap ${
                            activeTab === 'dashboard'
                                ? 'bg-gray-800 border-b-2 border-orange-500 text-orange-500'
                                : 'text-gray-400 hover:text-white hover:bg-gray-800/50'
                        }`}
                    >
                        Информационная панель
                    </button>
                    <button
                        onClick={() => setActiveTab('assistant')}
                        className={`py-2 sm:py-3 px-3 sm:px-4 rounded-t-lg font-medium text-xs sm:text-sm transition-colors whitespace-nowrap ${
                            activeTab === 'assistant'
                                ? 'bg-gray-800 border-b-2 border-orange-500 text-orange-500'
                                : 'text-gray-400 hover:text-white hover:bg-gray-800/50'
                        }`}
                    >
                        Ассистент
                    </button>
                    <button
                        onClick={() => setActiveTab('localization')}
                        className={`py-2 sm:py-3 px-3 sm:px-4 rounded-t-lg font-medium text-xs sm:text-sm transition-colors whitespace-nowrap ${
                            activeTab === 'localization'
                                ? 'bg-gray-800 border-b-2 border-orange-500 text-orange-500'
                                : 'text-gray-400 hover:text-white hover:bg-gray-800/50'
                        }`}
                    >
                        Локализация
                    </button>
                </nav>

                <div className="flex-1 overflow-hidden bg-gray-800">
                    {activeTab === 'dashboard' && <div className="p-6">Панель информации</div>}
                    {activeTab === 'assistant' && <DevAssistant />}
                    {activeTab === 'localization' && <Localization />}
                </div>
            </div>
        </div>
    );
};

export default DevTools;