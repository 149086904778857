import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DraggableHeader from './DraggableHeader';
import serviceIcon from '../../img/service.png';

const ComponentPanel = ({ components, setActiveComponent }) => {
    const { t } = useTranslation();
    const [isMinimized, setIsMinimized] = useState(false);
    const [selectedComponentId, setSelectedComponentId] = useState(null);
    const [position, setPosition] = useState({ bottom: 0, left: 0 });
    const panelRef = useRef(null);

    useEffect(() => {
        const updatePosition = () => {
            const desiredBottomOffset = 120; // расстояние от нижнего края в пикселях
            setPosition({
                bottom: desiredBottomOffset,
                left: 16 // 1rem = 16px, соответствует классу left-4
            });
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    const handleComponentClick = (component) => {
        setSelectedComponentId(component.id);
        setActiveComponent(component);
    };

    const handleMinimize = () => setIsMinimized(true);
    const handleRestore = () => setIsMinimized(false);

    const panelStyle = {
        position: 'fixed',
        bottom: `${position.bottom}px`,
        left: `${position.left}px`,
        width: '256px',
        maxHeight: `calc(100vh - ${position.bottom + 20}px)`, // 20px для верхнего отступа
        display: 'flex',
        flexDirection: 'column',
        zIndex: 20
    };

    return (
        <div ref={panelRef} style={panelStyle} className="bg-gray-100 shadow-lg rounded-lg">
            {!isMinimized ? (
                <>
                    <DraggableHeader
                        title={t('component_panel.title')}
                        onMinimize={handleMinimize}
                    />
                    <div className="flex-grow overflow-y-auto p-2">
                        {components.map((component) => (
                            <div
                                key={component.id}
                                className={`flex items-center mb-2 p-2 rounded-lg cursor-pointer hover:bg-gray-200 ${
                                    selectedComponentId === component.id ? 'bg-gray-300' : ''
                                }`}
                                onClick={() => handleComponentClick(component)}
                            >
                                <img
                                    src={serviceIcon}
                                    alt="icon"
                                    className="w-4 h-4 mr-1"
                                />
                                <span className="text-sm font-normal font-sans truncate">
                                    {component.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div
                    className="p-2 bg-white rounded-full shadow-lg cursor-pointer"
                    onClick={handleRestore}
                >
                    <span className="text-black">{t('component_panel.minimized_title')}</span>
                </div>
            )}
        </div>
    );
};

export default ComponentPanel;