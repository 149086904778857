// src/components/AuthDialog.js
import React, { useState, useRef, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    IconButton
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Lock, Mail, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import Turnstile from 'react-turnstile';
import { login, register } from '../services/apiService';

const StyledTextField = ({ icon: Icon, ...props }) => (
    <div className="relative">
        <Icon className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 z-10" />
        <TextField
            {...props}
            fullWidth
            variant="filled"
            InputProps={{
                sx: {
                    paddingLeft: '56px',
                    color: 'rgba(255,255,255,0.9)',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    '& input': {
                        paddingLeft: '0',
                    },
                    '& textarea': {
                        paddingLeft: '0',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.08)',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'rgba(255,255,255,0.08)',
                    },
                    '&::before': {
                        borderBottomColor: 'rgba(255,255,255,0.1)',
                    },
                    '&:hover::before': {
                        borderBottomColor: 'rgba(255,255,255,0.2) !important',
                    },
                    '&::after': {
                        borderBottomColor: '#FF6B6B',
                    },
                },
            }}
            InputLabelProps={{
                sx: {
                    marginLeft: '48px',
                    color: 'rgba(255,255,255,0.5)',
                    '&.Mui-focused': {
                        color: '#FF6B6B',
                        marginLeft: 0,
                    },
                    '&.MuiInputLabel-shrink': {
                        color: 'rgba(255,255,255,0.7)',
                        marginLeft: 0,
                    },
                },
            }}
        />
    </div>
);

const AuthDialog = ({ open, onClose, onSuccess, initialMode = 'choice' }) => {
    // Get translation function
    const { t } = useTranslation();

    // States
    const [mode, setMode] = useState(initialMode); // 'choice', 'login', 'register'
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        confirmPassword: '',
        name: '',
        lastName: '',
        bio: '',
        rememberMe: true
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loginCaptchaToken, setLoginCaptchaToken] = useState(null);
    const [registerCaptchaToken, setRegisterCaptchaToken] = useState(null);
    const loginCaptchaRef = useRef(null);
    const registerCaptchaRef = useRef(null);
    const [isVerifyingLogin, setIsVerifyingLogin] = useState(true);
    const [isVerifyingRegister, setIsVerifyingRegister] = useState(true);
    const siteKey = '0x4AAAAAAA1zpeuvtojKsnnF';

    useEffect(() => {
        if (open) {
            setMode(initialMode);
        }
    }, [initialMode, open]);

    const handleVerify = (token, type) => {
        if (type === 'login') {
            setLoginCaptchaToken(token);
            setIsVerifyingLogin(false);
        } else {
            setRegisterCaptchaToken(token);
            setIsVerifyingRegister(false);
        }
        setErrors(prev => ({
            ...prev,
            [type === 'login' ? 'loginCaptcha' : 'registerCaptcha']: ''
        }));
    };

    const handleExpire = (type) => {
        if (type === 'login') {
            setLoginCaptchaToken(null);
            setIsVerifyingLogin(true);
        } else {
            setRegisterCaptchaToken(null);
            setIsVerifyingRegister(true);
        }
    };

    const handleError = (type) => {
        if (type === 'login') {
            setLoginCaptchaToken(null);
            setIsVerifyingLogin(true);
        } else {
            setRegisterCaptchaToken(null);
            setIsVerifyingRegister(true);
        }
        setErrors(prev => ({
            ...prev,
            [type === 'login' ? 'loginCaptcha' : 'registerCaptcha']: t('auth.captcha_error')
        }));
    };

    const handleLogin = async () => {
        // Check for captcha
        if (!loginCaptchaToken) {
            setErrors(prev => ({
                ...prev,
                loginCaptcha: t('auth.captcha_required')
            }));
            return;
        }

        setIsLoading(true);
        try {
            const data = await login(
                formData.username,
                formData.password,
                loginCaptchaToken
            );

            if (formData.rememberMe) {
                localStorage.setItem('token', data.token);
            } else {
                sessionStorage.setItem('token', data.token);
            }

            // Send Google Tag Manager event
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'login_success',
                    user_id: data.userId || formData.username,
                    method: 'credentials'
                });
            }

            onSuccess(data.token);
            onClose();
        } catch (error) {
            setErrors({ submit: t('auth.login.error') });
            // Reset captcha on error
            if (loginCaptchaRef.current) {
                loginCaptchaRef.current.resetCaptcha();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegister = async () => {
        if (!validateForm()) return;

        if (!registerCaptchaToken) {
            setErrors(prev => ({
                ...prev,
                registerCaptcha: t('auth.captcha_required')
            }));
            return;
        }

        setIsLoading(true);
        try {
            // Basic registration only
            await register(
                formData.username,
                formData.email,
                formData.password,
                registerCaptchaToken
            );

            // After successful registration, perform login
            try {
                const loginData = await login(
                    formData.username,
                    formData.password,
                    registerCaptchaToken // Use the same captcha token
                );

                if (formData.rememberMe) {
                    localStorage.setItem('token', loginData.token);
                } else {
                    sessionStorage.setItem('token', loginData.token);
                }

                onSuccess(loginData.token);
                onClose();
            } catch (loginError) {
                setMode('login');
                setErrors({
                    submit: t('auth.register.success_login_required')
                });
            }

        } catch (error) {
            if (error.response?.data) {
                const errorData = error.response.data;

                if (errorData.username) {
                    setErrors(prev => ({
                        ...prev,
                        username: Array.isArray(errorData.username)
                            ? errorData.username[0]
                            : errorData.username
                    }));
                }

                if (errorData.email) {
                    setErrors(prev => ({
                        ...prev,
                        email: Array.isArray(errorData.email)
                            ? errorData.email[0]
                            : errorData.email
                    }));
                }

                if (errorData.error) {
                    if (error.response.status === 409 ||
                        errorData.error.includes('username already exists')) {
                        setErrors(prev => ({
                            ...prev,
                            username: t('auth.register.username_exists')
                        }));
                    } else if (errorData.error.includes('email already exists')) {
                        setErrors(prev => ({
                            ...prev,
                            email: t('auth.register.email_exists')
                        }));
                    } else {
                        setErrors(prev => ({
                            ...prev,
                            submit: errorData.error
                        }));
                    }
                }
            } else {
                setErrors(prev => ({
                    ...prev,
                    submit: error.message || t('auth.register.error')
                }));
            }

            if (registerCaptchaRef.current) {
                registerCaptchaRef.current.resetCaptcha();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.username) newErrors.username = t('errors.required_field');
        if (!formData.email && mode === 'register') newErrors.email = t('errors.required_field');
        if (!formData.password) newErrors.password = t('errors.required_field');

        if (mode === 'register') {
            if (formData.password !== formData.confirmPassword) {
                newErrors.confirmPassword = t('errors.passwords_not_match');
            }
            if (formData.password && formData.password.length < 8) {
                newErrors.password = t('errors.min_length', { count: 8 });
            }
            if (!registerCaptchaToken) {
                newErrors.registerCaptcha = t('auth.captcha_required');
            }
        } else if (mode === 'login') {
            if (!loginCaptchaToken) {
                newErrors.loginCaptcha = t('auth.captcha_required');
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user types
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    // Render choice mode
    const renderChoiceMode = () => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="px-6 py-8"
        >
            <Typography variant="h4" className="text-center font-bold text-orange-500 mb-4">
                {t('auth.welcome.title')}
            </Typography>
            <div className="max-w-xs mx-auto p-4 space-y-4">
                <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                    <Button
                        fullWidth
                        onClick={() => setMode('login')}
                        className="py-3 rounded-xl text-lg font-medium"
                        sx={{
                            background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
                            }
                        }}
                    >
                        {t('auth.welcome.login')}
                    </Button>
                </motion.div>
                <Button
                    fullWidth
                    onClick={() => setMode('register')}
                    className="py-3 rounded-xl text-lg font-medium"
                    sx={{
                        background: 'linear-gradient(45deg, #FFFFFF, #CCCCCC)',
                        '&:hover': {
                            background: 'linear-gradient(45deg, #CCCCCC, #999999)',
                        }
                    }}
                >
                    {t('auth.welcome.create_account')}
                </Button>
            </div>
        </motion.div>
    );

    // Render login mode
    const renderLoginMode = () => (
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
        >
            <DialogTitle className="px-6 pt-6 flex items-center">
                <IconButton onClick={() => setMode('choice')} className="mr-4 hover:bg-gray-800">
                    <ArrowLeft className="w-6 h-6 text-gray-400" />
                </IconButton>
                <Typography
                    variant="h4"
                    className="font-bold"
                    sx={{
                        background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
                        opacity: 0.9,
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                    }}
                >
                    {t('auth.login.title')}
                </Typography>
            </DialogTitle>
            <DialogContent className="px-6 pb-8">
                <Box className="space-y-6 max-w-sm mx-auto">
                    <StyledTextField
                        icon={User}
                        label={t('auth.login.username')}
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        error={!!errors.username}
                        helperText={errors.username}
                    />
                    <StyledTextField
                        icon={Lock}
                        label={t('auth.login.password')}
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.rememberMe}
                                onChange={(e) => handleChange({
                                    target: { name: 'rememberMe', value: e.target.checked }
                                })}
                                sx={{
                                    color: 'rgba(255,255,255,0.3)',
                                    '&.Mui-checked': {
                                        color: '#FF6B6B',
                                    },
                                }}
                            />
                        }
                        label={<Typography className="text-gray-400">{t('auth.login.remember_me')}</Typography>}
                    />

                    <div className="py-4 flex flex-col items-center">
                        <div className="w-full min-h-[85px] flex justify-center items-center bg-gray-800/20 rounded-lg">
                            <Turnstile
                                ref={loginCaptchaRef}
                                sitekey={siteKey}
                                onVerify={(token) => handleVerify(token, 'login')}
                                onExpire={() => handleExpire('login')}
                                onError={() => handleError('login')}
                                theme="dark"
                                className="flex justify-center"
                            />
                        </div>
                        {errors.loginCaptcha && (
                            <Typography color="error" variant="caption" className="mt-2">
                                {errors.loginCaptcha}
                            </Typography>
                        )}
                    </div>

                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <Button
                            fullWidth
                            onClick={handleLogin}
                            disabled={isLoading || !loginCaptchaToken || isVerifyingLogin}
                            className="py-3 rounded-2xl text-lg font-medium"
                            sx={{
                                background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
                                },
                                '&:disabled': {
                                    background: 'linear-gradient(45deg, #888, #999)',
                                }
                            }}
                        >
                            {isLoading ? t('common.loading') : isVerifyingLogin ? t('auth.verifying') : t('auth.login.submit')}
                        </Button>
                    </motion.div>
                </Box>
            </DialogContent>
        </motion.div>
    );

    // Render register mode
    const renderRegisterMode = () => (
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
        >
            <DialogTitle className="px-6 pt-6 flex items-center">
                <IconButton onClick={() => setMode('choice')} className="mr-4 hover:bg-gray-800">
                    <ArrowLeft className="w-6 h-6 text-gray-400" />
                </IconButton>
                <Typography
                    variant="h4"
                    className="font-bold"
                    sx={{
                        background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)',
                        opacity: 0.9,
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                    }}
                >
                    {t('auth.register.title')}
                </Typography>
            </DialogTitle>
            <DialogContent className="px-6 pb-8">
                <Box className="space-y-6 max-w-sm mx-auto">
                    <StyledTextField
                        icon={User}
                        placeholder={t('auth.register.username')}
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        error={!!errors.username}
                        helperText={errors.username}
                        hiddenLabel
                        variant="filled"
                    />
                    <StyledTextField
                        icon={Mail}
                        placeholder={t('auth.register.email')}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        hiddenLabel
                        variant="filled"
                    />
                    <StyledTextField
                        icon={Lock}
                        placeholder={t('auth.register.password')}
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        error={!!errors.password}
                        helperText={errors.password}
                        hiddenLabel
                        variant="filled"
                    />
                    <StyledTextField
                        icon={Lock}
                        placeholder={t('auth.register.confirm_password')}
                        name="confirmPassword"
                        type="password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        hiddenLabel
                        variant="filled"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.rememberMe}
                                onChange={(e) => handleChange({
                                    target: { name: 'rememberMe', value: e.target.checked }
                                })}
                                sx={{
                                    color: 'rgba(255,255,255,0.3)',
                                    '&.Mui-checked': {
                                        color: '#FF6B6B',
                                    },
                                }}
                            />
                        }
                        label={<Typography className="text-gray-400">{t('auth.register.remember_me')}</Typography>}
                    />

                    <div className="py-4 flex flex-col items-center">
                        <div className="w-full min-h-[85px] flex justify-center items-center bg-gray-800/20 rounded-lg">
                            <Turnstile
                                ref={registerCaptchaRef}
                                sitekey={siteKey}
                                onVerify={(token) => handleVerify(token, 'register')}
                                onExpire={() => handleExpire('register')}
                                onError={() => handleError('register')}
                                theme="dark"
                                className="flex justify-center"
                            />
                        </div>
                        {errors.registerCaptcha && (
                            <Typography color="error" variant="caption" className="mt-2">
                                {errors.registerCaptcha}
                            </Typography>
                        )}
                    </div>

                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <Button
                            fullWidth
                            onClick={handleRegister}
                            disabled={isLoading || !registerCaptchaToken || isVerifyingRegister}
                            className="py-3 rounded-2xl text-lg font-medium"
                            sx={{
                                background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
                                '&:hover': {
                                    background: 'linear-gradient(45deg, #FF8E53, #FF6B6B)',
                                },
                                '&:disabled': {
                                    background: 'linear-gradient(45deg, #888, #999)',
                                }
                            }}
                        >
                            {isLoading ? t('common.loading') : isVerifyingRegister ? t('auth.verifying') : t('auth.register.submit')}
                        </Button>
                    </motion.div>

                    {errors.submit && (
                        <Typography color="error" className="mt-2 text-center">
                            {errors.submit}
                        </Typography>
                    )}
                </Box>
            </DialogContent>
        </motion.div>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    backgroundColor: '#1a1a1a',
                    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.05), rgba(255,255,255,0.02))',
                    maxWidth: '440px',
                    width: '100%',
                    margin: '16px',
                    borderRadius: '16px',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'rgba(255,255,255,0.05)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255,255,255,0.1)',
                        borderRadius: '4px',
                        '&:hover': {
                            background: 'rgba(255,255,255,0.15)',
                        },
                    },
                }
            }}
        >
            <AnimatePresence mode="wait">
                {mode === 'choice' && renderChoiceMode()}
                {mode === 'login' && renderLoginMode()}
                {mode === 'register' && renderRegisterMode()}
            </AnimatePresence>
        </Dialog>
    );
};

export default AuthDialog;